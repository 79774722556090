@import url('https://fonts.googleapis.com/css?family=Yantramanav:400,300,100&subset=latin,latin-ext');

html {
    overflow: auto;
    height: 100%;
}

body {
    margin: 0;
    font-weight: 300;
    font-family: Yantramanav, Helvetica, sans-serif;
    background-color: #1E2B39;
    font-size: 15px;
    line-height: 1;
    color: #fff;
    min-block-size: 100vh;
    background: #19191f url(/assets/img/bg.svg) 50% 28px no-repeat;
    background-size: 1760px 852px;
    background-attachment: fixed;
}

body.christmass {
    background: #19191f url(/assets/img/bg-xmass.webp) 50% 0 no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

#popup {
    display: none;
    position: relative;
    z-index: 100;
}

#popup #popup-shadow {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: rgba(0,0,0,0.6);
}

#popup #popup-container {
    padding: 1%;
    background: #1E2B39;
    position: absolute;
    top: 25px;
    z-index: 1;
    max-width: 800px;
    width: 96%;
    left: 50%;
    box-shadow: 0 2px 50px rgba(255,255,255,0.1);
    border-radius: 5px;
    margin-bottom: 50px;
}

#popup #popup-container #popup-close {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.5em;
}

#popup #popup-container .topraider {
    line-height: 1.2;
}

.h5,h5{margin-bottom:.5rem;font-weight:500;line-height:1.2}

.h5,h5{font-size:1.25rem}

.text-center{text-align:center!important}

.img-fluid{max-width:100%;height:auto}

.display-3{font-size:4.5rem;font-weight:300;line-height:1.2}

.font-16 {
    font-size:16;
}

.font-8 {
    font-size:8;
}

.font-10 {
    font-size:10;
}

.color-red {
    color:#f23f42;
}

.color-green {
    color:#008000;
}

.color-blue {
    color:#1c84be
}

.color-orange {
    color:#fc850c
}

.color-purple {
    color:#9A9AC1
}

.color-light-blue {
    color:#8DCEEC
}

.color-light-green {
    color:#41AA9C
}

.mt-5 {
    margin-top: 5px;
}