/* Wrapper styling */
#filter-position-community-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
}

/* Community filter styling */
#filter-position-community-wrapper #filter-community {
    background: #39495e;
    padding: 7px 7px 6px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Dropdown menu styling */
#filter-position-community-wrapper ul {
    display: none;
    /* Hidden by default */
    position: absolute;
    top: 100%;
    left: 0;
    list-style: none;
    background: #39495e;
    padding: 7px;
    margin: 0;
    border-radius: 3px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    z-index: 10;
    width: max-content;
}

#filter-community-wrapper:hover ul {
    display: none;
}

/* Keep dropdown visible when hovering over the parent or dropdown */
#filter-community:hover ul,
#filter-community ul:hover {
    display: block;
}

/* List item styling */
#filter-community ul li {
    margin: 5px 0;
    cursor: pointer;
    border-radius: 3px;
    background-color: #4a596f;
    text-align: center;
}

/* Hover effect for list items */
#filter-community ul li:hover {
    background-color: #52647d;
}

/* Styling for flag images */
#filter-position-community-wrapper img {
    height: 16px;
    display: inline-block;
}

#filter-position-community-wrapper #server-select {
    background-color: #00060d;
    color: var(--main-panel-txt-color);
    border: 1px solid #081938;
    width: 250px;
    padding: 8px;
}

#filter-position-community-wrapper #position-select {
    background-color: #00060d;
    color: var(--main-panel-txt-color);
    border: 1px solid #081938;
    width: 80px;
    padding: 8px;
}

#filter-position-community-wrapper #galaxy-select {
    background-color: #00060d;
    color: var(--main-panel-txt-color);
    border: 1px solid #081938;
    width: 80px;
    padding: 8px;
}

#filter-position-community-wrapper {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    flex-wrap: wrap; 
}

#filter-community-wrapper {
    display: flex;
    align-items: center;
    gap: 10px; 
    flex: 1; 
}

#filter-position-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    flex: 1; 
}

/* Media query for small screens */
@media (max-width: 768px) {
    #filter-position-community-wrapper {
        flex-direction: column;
        align-items: flex-start; 
    }

    #filter-community-wrapper, #filter-position-wrapper {
        flex: none;
        width: 100%;
    }

    #filter-position-wrapper {
        justify-content: flex-start;
        padding: 7px 7px 6px;
    }

    #filter-server-wrapper {
        max-width: none;
        width: 94%
    }
}

.filters-panel {
    background-color: #1e2b39;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-top: 20px;
    transition: max-height 0.3s ease;
    /* max-height: 20px; */
}

#options-filters {
    background-color: #06090e;
    border-radius: 5px;
    margin-top: 5px;
}