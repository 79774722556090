.section-title {
  background-color: var(--main-paneltitle-bg-color);
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
  cursor: pointer;
  border-radius: 5px;
}

.section-title .fas {
  color: var(--main-paneltitle-txt-color);
  font-size: 20px;
}

.section-title .title {
  color: var(--main-paneltitle-txt-color);
  font-size: 15px;
  margin-left: 10px;
}
