.cr-input-label {
    margin-bottom: 10px;
    color: #f5a623;
    /* Custom label color */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    font-size: 2em;
    font-weight: 300;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 1);
}

.cr-input-label span {
    font-size: 2.6em;
    float: left;
}

.cr-converter-app .menu {
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    width: 200px;
    padding-right: 10px;
}

.cr-converter-app ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.cr-converter-app ul li {
    text-align: left;
    margin-bottom: 15px;
}

.cr-converter-app ul li a {
    display: block;
    color: #f1f1f1;
    background-image: -webkit-linear-gradient(top, #050f21, #050e1e);
    box-shadow: 0 0 10px #0009;
    border-radius: 7px;
    text-decoration: none;
    font-size: 0.6em;
    padding: 3%;
    border: 1px solid #081938;
    background-size: contain;
}

#input-wrapper {
    display: flex;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    border-radius: 10px;
}

#input-wrapper input[type="text"] {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    background-color: #00060d;
    color: var(--main-panel-txt-color);
    border: 1px solid #081938;
    border-radius: 2px;
}

.api-key-error-message {
    padding: 5px;
    height: 32px;
    line-height: 32px;
    border-bottom: 1px solid;
    border-radius: 3px;
    border-color: #d5684d;
    font-size: 16px;
    background: var(--main-invalid-key-bg-color);
    margin: 5px 0px;
}

#cr-form {
    margin: 20px 15px;
}

.cr-buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    margin-top: 20px;
}

.cr-button {
    margin-top: 0;
    margin-left: 10px;
    color: white;
    cursor: pointer;
    color: #f1f1f1;
    padding: 10px 20px;
    margin-left: 3%;
    font-weight: 300;
    line-height: 1.3;
    font-size: 15px;
    margin-bottom: 5px;
    background-image: -webkit-linear-gradient(top, #050f21, #050e1e);
    border: 1px solid #081938;
    background-size: contain;
    transition-duration: .5s;
}

#cr-form #cr-options {
    background: #000e16;
    width: 100%;
    border-radius: 7px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    display: block;
    margin-top: 15px;
}

#cr-form .cr-options-container {
    padding: 0 2% 2%;
}

#cr-form #cr-options-top-container {
    padding: 2%;
}

#cr-form #cr-options-top-container {
    padding: 2%;
}

#cr-form #cr-options-top-container label {
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
}

#cr-form #cr-options-top-container select {
    width: 100% !important;
    box-sizing: border-box;
}

#cr-form #cr-options-top-container #cr-report-title {
    width: 49%;
}

#cr-form #cr-options-top-container #cr-report-title input {
    padding: 1.5%;
    width: 97%;
}

#cr-form #cr-options-top-container #cr-report-title #cr-report-title-legend {
    float: right;
    color: #fff;
}

#cr-form .cr-options-container {
    padding: 0 2% 2%;
}

#cr-form #cr-options-container {}

#cr-form #cr-options .cr-options-box {
    float: left;
    width: 23.5%;
    padding: 1%;
}

#cr-form #cr-options .cr-options-box:first-child {
    padding-left: 0;
}

#cr-form #cr-options .cr-options-box:last-child {
    padding-right: 0;
}

#cr-form #cr-options .cr-options-box h4 {
    margin: 0;
}

#cr-form #cr-options .cr-options-box select,
#cr-form #cr-options .cr-options-box input[type="text"],
#cr-form #cr-options .cr-options-box textarea {
    border-radius: 3px;
    padding: 3%;
    width: 94%;
    border: 0;
    background-color: #00060d;
    color: var(--main-panel-txt-color);
    border: 1px solid #081938;
}

.input-invalid {
    border: 1px solid red;
}

#cr-form #cr-options #cr-options-checkboxes {}

#cr-form #cr-options #cr-options-checkboxes label {
    display: block;
    clear: both;
    margin-top: 10px;
    position: relative;
    padding-right: 20px;
}

#cr-form #cr-options #cr-options-checkboxes label input {
    position: absolute;
    top: 0;
    right: 0;
}

#cr-form #cr-options .cr-option-add {
    margin-top: 10px;
    color: #fff;
    display: inline-block;
}

#cr-form #cr-options #cr-options-raids {}

#cr-form #cr-options .cr-options-player input[type="text"],
#cr-form #cr-options #cr-options-raids input[type="text"] {
    margin-top: 10px;
}

#cr-form #cr-options .cr-options-player {}

#cr-form #cr-options .cr-options-player label, textarea {
    display: block;
}

#cr-form #cr-options .cr-options-player label {
    padding: 10px 0 0;
}

#cr-form #cr-options .cr-options-player textarea {}

#cr-form.cr-opt-active #cr-convert #cr-gotdebris-auto {
    display: none;
}

#cr-form.cr-opt-active #cr-convert:hover {
    padding-bottom: 1.2%;
    border-radius: 7px;
}

#cr-form #cr-convert::before {
    content: "\000AB";
}

#cr-form #cr-options-actions {
    padding: 2% 0 0;
}

#cr-form #cr-options-actions label:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

#cr-form #cr-options-actions label {
    padding: 2%;
    display: inline-block;
    border: 1px solid #39495e;
    cursor: pointer;
}

#cr-form button .fas {
    margin-right: 5px;
}

#report-wrapper h2 {
    font-size: 1.8em;
    margin-top: 0;
}

#report-wrapper #report-title h3 {
    margin-top: 0;
}

#report-wrapper h3 {
    font-size: 1.5em;
    font-weight: 300;
}

#report-wrapper #report-share input, #report-wrapper #report-title input, #report-wrapper #report-bbcode textarea {
    padding: 1%;
    font-size: 1.1em;
    border-radius: 5px;
    width: 98%;
    border: 0;
    background-color: #eee;
    color: #444;
    font-family: Yantramanav, Helvetica, sans-serif;
}

#report-wrapper #report-bbcode textarea {
    height: 150px;
}

#report-wrapper #report-social-share {
    padding-top: 20px;
}

#report-wrapper #report-preview #report-preview-html {
    padding: 10px;
    background-color: #06090E;
    border-radius: 10px;
}

#report-wrapper #report-preview #report-preview-html pre {
    margin: 0;
    font-family: inherit;
    white-space: pre-line;
	color: inherit;
    font-size: inherit;
}

#report-wrapper #report-preview #report-preview-html pre img {
    height: auto !important;
    max-width: 100%;
}

#report-wrapper #report-social-share ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
}


#report-wrapper #report-social-share ul li:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-left: 0;
}

#report-wrapper #report-social-share ul li:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

#report-wrapper #report-social-share ul li {
    position: relative;
    display: inline-block;
    width: 25%;
    flex: 1 0 auto;
    box-sizing: border-box;
    padding: 1%;
    font-size: 1.1em;
    background-color: #39495e;
    text-align: center;
    cursor: pointer;
    border-left: 1px solid #06090E;
}

#report-wrapper #report-preview #report-preview-html a {
    color: #ccc;
}

#report-wrapper #report-social-share ul li #share-public {
    position: absolute;
    padding: 10px;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #39495e;
    border: 1px solid rgba(255,255,255,0.6);
    box-sizing: border-box;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

#report-wrapper #report-social-share ul li #share-public #share-public-yes {
    padding: 5px;
    border-radius: 5px;
    background-color: #06090E;
}

#report-wrapper #report-social-share ul li #share-public a {
    color: #fff;
    display: block;
    text-decoration: none;
}

#report-wrapper #report-social-share ul li #share-public #share-public-no {
    padding: 5px;
    margin-top: 10px;
}

#report-wrapper #report-share img {
    margin-top: 20px;
    max-width: 500px;
    display: block;
}

.hint {
    font-size: 0.7em;
    font-style: italic;
    vertical-align: middle;
    color: #ddd;
}

.hint::before {
    content: "(";
}

.hint::after {
    content: ")";
}

@media (max-width: 50em) {

    #cr-form #cr-options .cr-options-box:nth-child(1n+3) {
        padding-top: 3%;
    }

    #cr-form #cr-options .cr-options-box:nth-child(2n+1) {
        padding-right: 3%;
    }

    #cr-form #cr-options .cr-options-box {
        float: none;
        display: inline-block;
        width: 47%;
        padding: 0%;
        vertical-align: top;
    }

    #cr-form #cr-options-top-container #cr-report-title {
        width: 97%;
        padding-right: 0;
    }

    #report-players .report-players-block {
        width: 44%;
    }

    #report-players #report-versus {
        display: none;
    }
}

@media (max-width: 37.5em) {
    #report-players h3 {
        text-align: center;
    }

    #report-players .report-players-block {
        width: 96%;
        padding: 2%;
        float: none;
        margin-bottom: 15px;
    }

    #report-players .report-players-block ul {
        padding: 2%;
    }

    #cr-form #cr-options .cr-options-box:first-child {
        padding-top: 1% !important;
    }

    #cr-form #cr-options .cr-options-box {
        float: none;
        display: block;
        width: 98%;
        padding: 4% 1% 1% !important;
        vertical-align: top;
    }

    .cr-converter-app .menu {
        display: none;
    }
}