.result-main {
  margin-top: 5px;
}

.result-main .result {
  flex-direction: column;
  align-items: flex-end;
  margin-top: 2.5px;
}

.result-main .resources-bar {
  display: flex;
  justify-content: flex-end;
  padding: 2.5px;
  width: 98%;
}

.result-main .resources-bar .bar {
  width: 80%;
  display: flex;
  justify-content: center;
  color: var(--main-panel-txt-color);
  background: var(--main-panel-bg-color);
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 10px;
}

.result-main .resources-bar .bar div:first-child {
  text-align: left;
}

.result-main .resources-bar .bar div {
  width: 30%;
  text-align: right;
}


@media (max-width: 768px) {
  .result-main .result {
    flex-direction: column;
    align-items: center;
    /* Center-align content */
    margin-top: 2.5px;
  }

  .result-main .resources-bar {
    justify-content: space-between;
    /* Space evenly */
    padding: 2.5px;
    width: 100%;
  }

  .result-main .resources-bar .bar {
    width: 100%;
    /* Full width */
    text-align: center;
    /* Center-align text */
  }
}