.settings-panel{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.7);;
}

.settings-panel .card{
    padding: 10px 10px;
    background-color: var(--main-panel-txt-color);
    color: var(--main-paneltitle-bg-color);
    border-radius: 5px;
    font-weight: bold;
}

.settings-panel .card .title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.settings-panel .card .title .fas{
    cursor: pointer;
}

.settings-panel .setting-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
}

.settings-panel .setting-section.convert-consumption{
    margin-top: 5px;
}

.settings-panel .setting-section .control .fas{
    font-size: 30px;
    padding-left: 40px;
    cursor: pointer;
}

.settings-panel .setting-section .control.conversion .text-input{
    width: 35px;
    padding: 0px;
    text-align: center;
    background-color:  var(--main-paneltitle-bg-color);
    color: var(--main-panel-txt-color);
}