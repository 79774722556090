/* table.ally_ranks {
    border-collapse: collapse;
    background: 0 0;
    border-color: #000813;
    max-width: 100% !important;
    width: 100% !important;
} */

table.ally_ranks, div.pagination {
    border-collapse: separate; /* Change to separate */
    border-spacing: 0; /* Add border spacing */
    background: #000e16;
    border: 1px solid #000;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px #142c52, inset 0 -1px 0 rgba(63,59,113,.2), 0 9px 16px 0 rgba(0,0,0,.3), 0 4px 3px 0 rgba(0,0,0,.3), 0 0 0 1px #040f1f;
    padding: 10px;
    width: 100%;
}

/* Adjust header style */
table.ally_ranks th {
    text-align: center;
    padding: 8px; /* Increase padding */
}

/* Adjust cell style */
table.ally_ranks td {
    text-align: center;
    padding: 8px; /* Increase padding */
}

/* Adjust anchor style */
.classstat5 {
    color: #fff;
    text-decoration: none;
}

/* Remove unwanted style for image */
.classstat1 img {
    height: 16px;
}

#ally_content {
    overflow: hidden;
    position: relative;
    margin: 20px 15px;
}

.barraclass {
    height: 12px;
    border: 1px solid #000;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 2;
    background: rgba(0, 0, 0, .25);
    box-shadow: inset 0 1px 2px rgba(0,0,0,.25), 0 1px rgba(255,255,255,.08);
    line-height: 4px;
    padding: 1px;
    font-size: 11px;
    width: 100%;
    float: left;
    text-decoration: none;
    text-shadow: 0 0 21px rgba(223,206,228,.5), 0 -1px 0 #162e3c;
    vertical-align: unset;
    margin: 7px 0 7px 0;
}

.barraclass th {
    color: #ccc;
    background: 0 0
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.classificabarra {
    height: auto; /* Change to auto height */
    border: 1px solid #000;
    border-radius: 3px;
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 2;
    background: rgba(0, 0, 0, .25);
    box-shadow: inset 0 1px 2px rgba(0,0,0,.25), 0 1px rgba(255,255,255,.08);
    font-size: 12px; /* Adjust font size */
    text-decoration: none;
    text-shadow: 0 0 21px rgba(223,206,228,.5), 0 -1px 0 #162e3c;
    vertical-align: middle; /* Change to middle */
    margin: 7px 0; /* Remove left and right margin */
    padding: 5px; /* Increase padding */
}

.classificabarra.line {
    cursor: pointer;
    background: unset;   
}

.classificabarra.line:hover {
    background: #0a172b;
    -moz-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.barraclass th {
    color: #ccc;
    background: none; /* Remove background */
}

.tabstatistica td {
    border: none;
    color: #a9a9a9;
    text-align: center;
    padding: 0 10px;
    border-color: #000;
    line-height: 23px;
}

td.report-summary-positive {
    color: rgb(63, 255, 63);
}

td.report-summary-negative {
    color: rgb(255, 51, 51);
}

.top-type-select {
    background-color: #00060d;
    border: 1px solid #081938;
    border-radius: 3px;
    color: var(--main-panel-txt-color);
    padding: 5px;
}

#filter-community-wrapper {
    position: relative;
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
}

#filter-community-wrapper #filter-community {
    background: #39495e;
    padding: 7px 7px 6px;
    border-radius: 3px;
}

#filter-community-wrapper ul {
    display: none;
    position: absolute;
    list-style: none;
    background: #39495e;
    height: 200px;
    overflow-y: auto;
    margin: 0;
    padding: 7px 30px 7px 7px;
    box-shadow: 0 2px 10px #000c;
    z-index: 10;
}

#filter-community-wrapper:hover ul{display:block}

#filter-community-wrapper ul li:first-child {
    margin-top: 0;
}

#filter-community-wrapper ul li {
    margin-top: 5px;
    cursor: pointer;
}