.panda {
    display: none;
}

.active,
.active2 {
    color: #929292 !important;
    font-weight: 600;
}

#landing {
    min-height: 400px;
    width: 100%;
    background-size: cover;
    text-align: center;
}

.cover {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 5vh;
}

.cont-1 {
    width: 100%;
}

.pink {
    color: #FFACAC;
}

.cont-1 h1 {
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-weight: 800;
    font-size: 42px;
    margin: 1vh 0;
}

.nav-logo {
    height: 5vh;
    width: auto;
}

.option-tag {
    display: inline-block;
    padding: 4px 8px;
    margin: 4px;
    border-radius: 4px;
    font-size: 14px;
}

.required-option {
    background-color: #ffcccc; /* Light red background for required options */
}

.optional-option {
    background-color: #ccffcc; /* Light green background for optional options */
}

.cont-1 p {
    margin-top: -1vh;
    margin-bottom: 7vh;
    color: #7e7d7d;
    font-size: 25px;
    font-weight: 300;
}

#logodiscord {
    width: 200px;
}

#about {
    width: 100%;
    height: auto;
    padding: 12vh 0;
    background: #121316;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: white;
}

#about p {
    width: 70%;
    font-size: 17px;
    color: grey;
    text-align: center;
}

#about h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    width: 90%;
    text-align: center;
    font-size: 42px;
}

.row {
    display: flex;
    flex-direction: row;
    margin: 3vh 0;
}

.row i {
    margin: 1vh;
    cursor: pointer;
    color: rgb(214, 214, 214);
    font-size: 40px;
}

#collaborator {
    height: 100vh;
    width: 100%;
    background: url('/assets/img/discord/collab.png');
    background-size: cover;
    background-position: center;
}

#collaborator h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 42px;
    width: 90%;
    text-align: center;
    color: white;
}

#collaborator p {
    width: 60%;
    font-size: 17px;
    color: rgb(189, 189, 189);
    text-align: center;
}

#collaborator i {
    font-size: 50px;
    color: white;
    cursor: pointer;
    margin: 2vh;
}

.btn-invite-server {
    margin: 3vh 0;
    padding: 2vh 4vh;
    border-radius: 5px;
    text-decoration: none;
    color: white;
    font-size: 16px;
    letter-spacing: 5px;
    word-spacing: 7px;
    background: rgb(149, 153, 226);
    background: linear-gradient(45deg, rgba(149, 153, 226, 1) 0%, rgba(220, 134, 128, 1) 100%);
    font-weight: 400;
}

.colab {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgb(19, 20, 24);
    background: linear-gradient(180deg, rgba(19, 20, 24, 1) 12%, rgba(255, 255, 255, 0) 100%);
}

#commands {
    width: 92%;
    padding: 0 4%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #18191D;
    box-sizing: unset;
}

#commands h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 42px;
    width: 90%;
    margin-bottom: 0 !important;
    text-align: center;
    color: white;
}

#commands p {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    text-align: center;
    width: 92%;
    margin-bottom: 5vh;
    font-weight: 600;
    margin-top: 2vh;
    color: #EEA47F;

}

.command-bg {
    width: 92%;
    padding: 17vh 4%;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    background: url();
    align-items: center;
    justify-content: center;
}

.command-img {
    width: auto;
    height: 40vh;
    align-self: flex-end;
}

.command-grid {
    width: 80%;
    display: flex !important;
    flex-wrap: wrap !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
}

.command-box {
    margin: 3vh 5%;
    display: flex;
    width: 40%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.command-p-h {
    font-size: 16px !important;
    color: rgb(236, 236, 236) !important;
    font-weight: 500 !important;
}

.command-p.legend {
    text-align: center !important;
    margin: 10px 0px 0px 00px !important;
}

.command-p {
    margin: 0 !important;
    margin-bottom: 0.5vh !important;
    font-size: 14px !important;
    color: rgb(160, 158, 158) !important;
    margin-left: 8vh !important;
    text-align: left !important;
    font-weight: 500 !important;
}



.sno-com {
    color: rgb(221, 218, 218);
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    font-size: 17px;
    margin: 0.5vh 0;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.command-orange {
    background: #EEA47F;
    color: rgb(20, 20, 31);
    border-radius: 5px;
    font-weight: 600 !important;
    font-size: 16px;
    padding: 1vh 4vh;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 1vh;
}

.footer {
    width: 92%;
    background: #121316;
    min-height: 7vh;
    padding: 1vh 4%;
    display: flex;
    flex-direction: row;
    color: rgb(233, 233, 233);
    align-items: center;
    justify-content: space-between;
}

.big-nav-show {
    display: flex !important;
}

.footer p {
    color: rgb(196, 196, 196);
}

.ficon {
    margin: 0 1vh !important;
    font-size: 20px !important;
}

#nav-tog {
    display: block;
}

#logoBot {
    margin-top: 7vh !important;
    height: 18vh;
}

.footer-logo {
    height: 7vh;
    width: auto;
}

.footer-mtop {
    margin-top: -1vh;
}

.nav-small-display {
    display: none;
    flex-direction: column;
}

.about-img {
    width: 60%;
    margin: 4vh 20%;
}

#feature-grid {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 5vh;
}

#features {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 92%;
    padding: 13vh 4%;
    background: url('/assets/img/discord/features.jpg');
    background-size: cover;
    box-sizing: unset;
}

.feature-card {
    width: 32vh;
    margin: 0 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5vh;
    border-radius: 8px;
    color: white;
}

.feature-card img {
    height: 15vh;
    width: auto;
    margin: 2vh 0;
}

.feature-card h3 {
    font-size: 24px;
    font-weight: 500;
    margin: 0.5vh 0;
    color: #EEA47F;
    font-family: 'Montserrat', sans-serif;
}

.feature-card p {
    padding: 1vh 3vh;
    background: #18191D;
    border-radius: 7px;
    margin: 0.5vh 0;
    color: #929292;
    font-family: 'Montserrat', sans-serif;
}

#features h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 42px;
    width: 90%;
    /* margin-bottom:0 !important; */
    text-align: center;
    color: white;
}

@media screen and (max-width:1250px) {
    .cont-2 img {
        height: 63vh;
    }

    #feature-grid {
        width: 90%;
    }
}

@media screen and (max-width:1100px) {
    .cont-2 img {
        height: 56vh;
    }

    .cont-1 h1 {
        font-size: 39px;
    }

    #feature-grid {
        width: 100%;
    }
}

@media screen and (max-width:1000px) {
    .cover {
        padding-top: 5vh;
        flex-direction: column;
        height: auto;
        align-items: center;
        justify-content: center;
    }

    #landing {
        overflow: hidden;
    }

    .cont-1 {
        margin-top: 0;
        width: 90%;
        height: auto;
    }

    .panda {
        display: flex;
    }

    .nav-logo {
        display: none;
    }

    .cont-2 {
        display: none;
    }

    #about p {
        width: 90%;
    }

    #collaborator p {
        width: 80%;
    }

    .command-grid {
        width: 90%;
        justify-content: space-between !important;
    }

    .command-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .about-img {
        width: 70%;
        margin: 4vh 15%;
    }
}

@media screen and (max-width:900px) {
    .command-grid {
        width: 100%;
        display: flex !important;
        flex-wrap: nowrap;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
    }

    .command-box {
        width: 80%;
        margin-right: 10%;
    }

    .nav {
        min-height: 6vh;
        height: auto;
        background: #121316;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.326);
        z-index: 200;
    }

    .nav-icon {
        display: flex;
        flex-direction: column;
        height: 4vh;
        width: 4vh;
        cursor: pointer;
        margin: 1vh 0;
        /* background:white; */
        margin-left: 4vh;
    }

    .bar-nav {
        height: 6px !important;
        margin: 3px 0;
        border-radius: 5px;
        width: 100%;
        background: rgb(44, 44, 44);
    }

    .nav-item,
    .nav-item2 {
        text-decoration: none;
        color: #4E4E4E;
        padding: 2vh 4vh;
    }

    .nav-small-display {
        display: flex;
        flex-direction: column;
    }

    #nav-tog {
        display: flex;
        flex-direction: column;
    }

    #navtoggle {
        display: none;
    }

    .big-nav-show {
        display: none !important;
    }

    .about-img {
        width: 90%;
        margin: 4vh 5%;
    }

}

@media screen and (max-width:700px) {
    .cont-2 img {
        height: auto;
        width: 90%;
    }

    #collaborator p {
        width: 90%;
        font-size: 16px;
    }

    #about p {
        font-size: 16px;
    }

    h1 {
        font-size: 37px;
    }

    .command-img {
        min-width: auto;
        width: 90%;
        min-height: auto;
        height: auto;
        align-self: flex-end;
    }
}

@media screen and (max-width:400px){
    .btn-invite-server {
        font-size: 12px;
    }
}
