.result-summary {
  width: 98%;
  padding: 2.5px;
  display: flex;
}

.result-summary .result-resources{
  width: 80%;
  padding: 0px 2.5px;
}

.result-summary .result-resources .data {
  color: var(--main-panel-txt-color);
  background: var(--main-panel-bg-color);
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 10px;
  box-shadow: inset 0 1px 1px #142c52, inset 0 -1px 0 rgba(63,59,113,.2), 0 9px 16px 0 rgba(0,0,0,.3), 0 4px 3px 0 rgba(0,0,0,.3), 0 0 0 1px #040f1f;
}

.result-summary .row {
  display: flex;
  justify-content: space-around;
}

.result-summary .row div {
  width: 30%;
  text-align: right;
}

.result-summary .row div:first-child {
  text-align: left;
}

.result-summary .title {
  width: 20%;
  padding: 0px 2.5px;
}

.result-summary .title .text{
  height: 100%;
  color: var(--main-panel-txt-color);
  background: var(--main-panel-bg-color);
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .result-summary {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .result-summary .result-resources {
    width: 100%;
    padding: 0px 2.5px;
  }

  .result-summary .title {
    display: none;
  }
}