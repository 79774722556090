::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-corner {
    display: none;
}

::-webkit-scrollbar-thumb {
    background: rgba(115,205,240,.9);
}

::-webkit-scrollbar-track {
    background: rgba(115,205,240,.2);
}

::-webkit-scrollbar-track-piece {
}

::selection {
    background: #09c;
    color: #fff;
    text-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #081938 !important;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #00060d inset;
  transition: background-color 5000s ease-in-out 0s;
}

main {
	/*height: 100vh;*/
	/* display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center; */
	max-width: 1100px;
	margin: 0 auto;
	margin-top: 50px;
	position: relative;
	z-index: 1;
}

* {
    box-sizing: border-box;
}

main.discord-active {
    margin: 0;
    width: 100%;
    max-width: 100%;
}

.mt5 {
	margin-top: 5px;
}

.clearfix {
    display: block;
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.notification {
    padding: 8px;
    color: white;
    opacity: 0.83;
    transition: opacity 0.6s;
    /* margin-bottom: 15px; */
    margin: 20px 15px;
}

.notification.warning {
    background-color: #ff9800;
}

@media only screen and (max-width: 1100px) {
	main {
		width: 94%;
	}
}

@keyframes snow {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(100vh);
        opacity: 0;
    }
}

.snow-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Ensure the snow doesn't interfere with interactions */
    overflow: hidden; /* Ensure no scrollbars appear */
    z-index: 9999; /* Keep it above all other elements */
}

.snowflake {
    position: absolute;
    top: -10px;
    color: white;
    font-size: 16px;
    pointer-events: none;
    animation: snow 10s linear infinite;
}

.snowflake:nth-child(odd) {
    animation-duration: 8s;
}