.custom-report-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.6);
}

.custom-report-panel .card {
  padding: 10px 10px;
  background-color: var(--main-panel-txt-color);
  color: var(--main-paneltitle-bg-color);
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
}

.custom-report-panel .card .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.custom-report-panel .card .title .fas {
  cursor: pointer;
}

.custom-report-panel .card .section {
  border-top: 1px solid black;
}

.custom-report-panel .card .save {
  display: flex;
  justify-content: center;
}

.custom-report-panel .card .section .description {
  margin-top: 10px;
  text-align: center;
}

.custom-report-panel .card .section .options {
  margin: 5px 0px;
  display: flex;
  justify-content: center;
}

.custom-report-panel .card .section .options div {
  min-width: 40px;
  height: 30px;
  display: flex;
  margin: 5px 10px;
  padding: 0px 5px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.custom-report-panel .card .section .options div .text-input {
  background-color: var(--main-paneltitle-bg-color);
  color: var(--main-paneltitle-txt-color);
  width: 100px;
  height: 30px;
  text-align: center;
  padding: 0px;
}
.custom-report-panel .card .section .options.wrap {
  flex-wrap: wrap;
}

.custom-report-panel .card .section .options div.selected {
  border: 2px solid var(--main-paneltitle-bg-color);
}
