.transport-summary {
  width: 98%;
  padding: 2.5px;
  display: flex;

}

.transport-summary .result-transport {
  width: 80%;
  padding: 0px 2.5px;
}

.transport-summary .result-transport .data {
  color: var(--main-panel-txt-color);
  background: var(--main-panel-bg-color);
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 10px;
  text-align: center;
  box-shadow: inset 0 1px 1px #142c52, inset 0 -1px 0 rgba(63, 59, 113, .2), 0 9px 16px 0 rgba(0, 0, 0, .3), 0 4px 3px 0 rgba(0, 0, 0, .3), 0 0 0 1px #040f1f;
}

.transport-summary .result-transport .data>div {
  padding: 5px 0px;
}

.transport-summary .title {
  width: 20%;
  padding: 0px 2.5px;
}

.transport-summary .title .text {
  height: 100%;
  color: var(--main-panel-txt-color);
  background: var(--main-panel-bg-color);
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .transport-summary {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .transport-summary .title,
  .transport-summary .result-transport {
    width: 100%;
    margin-top: 5px;
  }
}