.fleet-details {
  display: flex;
  margin-top: 2.5px;
  transition-duration: .5s;
}

.fleet-details .fleet-label {
  color: var(--main-panel-txt-color);
  background-color: var(--main-panel-bg-color);
  ;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  font-size: 15px;
}

.fleet-details .fleet-label.expanded {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  background-color: transparent;
  width: 2%;
}

.fleet-details .fleeter-details-panel {
  width: 100%;
  flex-wrap: wrap;
  margin-top: 2.5px;
  display: flex;
}

.fleet-details .fleet-label .fa-caret-right {
  font-size: 20px;
  margin-bottom: 8px;
}

.fleet-details .fleet-label .fa-caret-down {
  font-size: 20px;
  margin-right: 8px;
}

.fleet-details .card .input-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Center vertically on small screens */
}

.fleet-details .card .text-input {
  text-align: center;
  width: 100%;
  width: 120px;
  height: 15px;
  font-size: 13px;
}


@media (max-width: 1100px) {
  .fleet-details .card .input-row {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .fleet-details .attack-fleet-details {
    width: calc(100% / 4);
  }
}

@media (max-width: 768px) {
  .fleet-details {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .fleet-details .fleet-label.expanded {
    width: 100%;
    writing-mode: horizontal-tb;
    transform: none;
  }

  .fleet-details .fleet-label {
    text-align: center;
    background: transparent;
  }

  .fleet-details .fleet-label .fa-caret-right,
  .fleet-details .fleet-label .fa-caret-down {
    margin-right: 8px;
    margin-bottom: 0;
  }

  .fleet-details .card .text-input {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .fleet-details .attack-fleet-details {
    width: calc(100% / 4);
  }
}

@media (max-width: 650px) {
  .fleet-details .attack-fleet-details {
    width: calc(100% / 3);
  }
}

@media (max-width: 37.5em) {
  .fleet-details .attack-fleet-details {
    width: calc(100% / 2);
  }
}

@media (max-width: 300px) {
  .fleet-details .attack-fleet-details {
      width: 100%;
  }
}