.autocompleteTextBox__items {
    padding: 1px;
    border-radius: 4px;
    background-color: #00060d;
    min-height: 31px;
}

.autocompleteTextBoxNoItems__text {
    display: inline-block;
    margin: 4px 10px;
    padding: 5px 10px;
    line-height: 28px;
    vertical-align: bottom;
    background-color: #ebecf0;
}
.autocompleteTextBox .autocompleteTextBoxItem {
    display: inline-block;
    margin: 4px;
}
.autocompleteTextBox .autocompleteTextBoxItem__text {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 1px solid lightblue;
}
.autocompleteTextBox .autocompleteTextBoxItem__text, .autocompleteTextBox .autocompleteTextBoxItem__remove, .autocompleteTextBox .autocompleteTextBoxItem .autocompleteTextBoxAddBtn {
    padding: 5px 10px;
    /* height: 40px; */
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    vertical-align: bottom;
    color: #f5a623;
}
.autocompleteTextBox .autocompleteTextBoxItem__remove {
    background-color: gainsboro;
    cursor: pointer;
    fill: grey;
    border: 1px solid #c8c8c8;
    padding: 5px;
    outline: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
@media (hover: hover) {
    .autocompleteTextBox .autocompleteTextBoxItem__remove:hover {
        background-color: #c8c8c8;
   }
}
.autocompleteTextBox .autocompleteTextBoxItem .autocompleteTextBoxAddBtn {
    border: none;
    width: 40px;
    font-family: "Open Sans";
    margin: 0;
    font-size: 30px;
    background-color: gainsboro;
    padding: 0 10px;
    color: grey;
    border: 1px solid #c8c8c8;
    border-radius: 4px;
}
@media (hover: hover) {
    .autocompleteTextBox .autocompleteTextBoxItem .autocompleteTextBoxAddBtn {
        display: none;
   }
}
.autocompleteTextBox .autocompleteTextBoxNewItem {
    display: block;
    position: relative;
    margin: 4px;
}
.autocompleteTextBox .autocompleteTextBoxNewItem__container {
    position: absolute;
    border-radius: 4px;
    width: 100%;
}
.autocompleteTextBox .autocompleteTextBoxNewItem__text {
    min-width: 150px;
    padding: 10px;
    outline: none;
    background-color: lightblue;
    /*height: 40px;*/
    box-sizing: border-box;
}
.autocompleteTextBoxNewItem__text.warning {
    border: 1px solid red; /* Red border for warning */
}
.autocompleteTextBox .autocompleteTextBoxNewItem__list {
    position: absolute;
    list-style-type: none;
    padding: 0;
    width: 100%;
    background-color: #00060d;
    margin: 0;
    box-sizing: border-box;
    border-top: solid 1px darkgray;
    max-height: 230px;
    overflow: hidden;
    overflow-y: scroll;
    z-index: 999999;
}
.autocompleteTextBox .autocompleteTextBoxNewItem__listitem {
    padding: 8px 10px;
    cursor: pointer;
}
@media (hover: hover) {
    .autocompleteTextBox .autocompleteTextBoxNewItem__listitem:hover {
        background-color: #8ec9dc;
   }
}
.autocompleteTextBox .hidden {
    display: none;
}
