.player-totals-main {
  display: flex;
  width: 100%;
}

.player-totals-main .player-totals-label {
  color: var(--main-panel-txt-color);
  background-color: var(--main-panel-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  font-size: 15px;
  margin-top: 5px;
}

.player-totals-main .player-totals-label.expanded {
  margin-top: 0px;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  background-color: transparent;
  width: 2%;
}

.player-totals-main .player-totals-label .fa-caret-right {
  font-size: 20px;
  margin-bottom: 8px;
}

.player-totals-main .player-totals-label .fa-caret-down {
  font-size: 20px;
  margin-right: 8px;
}

.player-totals-main .player-totals {
  width: 98%;
  flex-wrap: wrap;
  justify-content: center;
}

.player-totals-main .player-totals .player-total {
  display: flex;
  width: 100%;
  padding: 2.5px;
}

.player-totals-main .player-totals .player-total .player-name {
  width: 20%;
  padding: 0px 2.5px;
}

.player-totals-main .player-totals .player-total .player-name .text {
  height: 100%;
  color: var(--main-panel-txt-color);
  background: var(--main-panel-bg-color);
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.player-totals-main .player-totals .player-total .result-resources {
  width: 80%;
  padding: 0px 2.5px;
}

.player-totals-main .player-totals .player-total .data {
  color: var(--main-panel-txt-color);
  background: var(--main-panel-bg-color);
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 10px;
  box-shadow: inset 0 1px 1px #142c52, inset 0 -1px 0 rgba(63, 59, 113, .2), 0 9px 16px 0 rgba(0, 0, 0, .3), 0 4px 3px 0 rgba(0, 0, 0, .3), 0 0 0 1px #040f1f;
  height: 100%;
}

.player-totals-main .player-totals .player-total .row {
  display: flex;
  align-items: center;
}

.player-totals-main .player-totals .player-total .row.settlement-title {
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 5px;
}

.player-totals-main .player-totals .player-total .row div {
  width: 30%;
  text-align: right;
}

.player-totals-main .player-totals .player-total .row div.green {
  color: rgb(13, 255, 0);
}

.player-totals-main .player-totals .player-total .row div.red {
  color: rgb(252, 3, 32);
}

.player-totals-main .player-totals .player-total .row div:first-child {
  text-align: left;
}

.player-totals-main .player-totals .player-total .row.title {
  margin-bottom: 5px;
}

.player-totals-main .player-totals .player-total .row .contribution-prompt {
  display: flex;
  align-items: center;
}

.player-totals-main .player-totals .player-total .row .contribution-prompt div {
  width: 50%;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;
}

.player-totals-main .player-totals .player-total .row .contribution-prompt div.settlement-full {
  color: grey;
}

.player-totals-main .player-totals .player-total .row .contribution-prompt .text-input.settlement-full {
  background-color: grey;
}

.player-totals-main .player-totals .player-total .row .contribution-prompt .text-input {
  width: 50%;
  text-align: center;
  height: 15px;
}

@media (max-width: 768px) {
  .player-totals-main {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .player-totals-main .player-totals-label {
    width: 100%;
    /* Full width */
    text-align: center;
    /* Center-align text */
    background: transparent;
  }

  .player-totals-main .player-totals {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .player-totals-main .player-totals-label.expanded {
    writing-mode: horizontal-tb;
    /* Reset text orientation */
    transform: none;
    /* Reset rotation */
    width: 100%;
  }

  .player-totals-main .player-totals-label .fa-caret-right,
  .player-totals-main .player-totals-label .fa-caret-down {
    margin-right: 8px;
    margin-bottom: 0;
    /* Reset margin */
  }
  
  .player-totals-main .player-totals .player-total {
    width: 100%;
    flex-direction: column;
    align-items: center;
    /* Full width */
  }

  .player-totals-main .player-totals .player-total .player-name {
    width: 100%;
    /* Full width */
    text-align: center;
    /* Center-align text */
  }

  .player-totals-main .player-totals .player-total .result-resources {
    width: 100%;
    padding: 2.5px;
    margin-top: 3px;
  }

  .player-totals-main .player-totals .player-total .row .contribution-prompt {
    flex-direction: column; /* Stack items vertically */
    align-items: flex-end; /* Right-align items */
  }

  .player-totals-main .player-totals .player-total .row .contribution-prompt div {
    width: 100%; /* Full width for the value */
    padding-right: 0; /* Remove padding on small screens */
    margin-top: 5px; /* Add margin between value and input */
  }

  .player-totals-main .player-totals .player-total .row .contribution-prompt .text-input {
    text-align: center; /* Center-align text */
  }
}