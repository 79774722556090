header {
    color: #fff;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
}

header::before {
    content: "";
    background-color: #000e16;
    /* Set your desired background color */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: -1;
    /* Place it behind the header content */
}

header .logo {
    display: flex;
    align-items: center;
}

header .logo img {
    height: auto;
    margin-right: 10px;
}

header .logo span {
    display: flex;
    flex-direction: column;
}

header .logo span .part1 {
    color: #ccc;
    font-size: 18px;
    font-weight: bold;
}

header .logo span .part2 {
    font-size: 14px;
    color: #ccc;
}

header .header-menus {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: auto;
    /* Adjust the gap as needed */
}

/* Style for header menu items */
header .header-menu-item {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    text-transform: uppercase;
    color: #596477;
    cursor: pointer;
}

header .header-menu-item:hover {
    text-decoration: underline;
}

header .language-dropdown {
    position: relative;
    display: inline-block;
    margin-left: auto;
    cursor: pointer;
}

header .language-dropdown select {
    display: none;
}

header .language-dropdown .dropdown-content {
    display: none;
    position: absolute;
    background-color: #555;
    z-index: 1000;
    text-align: left;
    border-radius: 5px;
    max-height: 200px;
    overflow-y: auto;
    right: 0;
    /* Align the dropdown content to the right */
    left: unset;
    /* Remove the left positioning */
}

header .language-dropdown:hover .dropdown-content {
    display: block;
}

header .language-dropdown .dropdown-content ul {
    list-style: none;
    margin: 0;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(3, minmax(120px, 1fr));
    gap: 10px;
}

header .language-dropdown .dropdown-content li {
    display: flex;
    align-items: center;
    padding: 5px;
    color: #fff;
    font-size: 14px;
}

header .language-dropdown .dropdown-content li.active-lang {
    background-color: #39495e;
}

header .language-dropdown .dropdown-content img {
    width: 32px;
    height: auto;
    margin-right: 5px;
}

header .Navbar-modalMask {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: 0;
    background-color: transparent;
    transition: background .5s linear;
    z-index: 101;
}

header .Navbar-modalOpen {
    display: none;
    position: relative;
    float: right;
    height: 45px;
    line-height: 45px;
    padding-right: 45px;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
}

header .i-menu {
    background-image: url(/assets/img/menu.png);
}

header .i-lang {
    background-image: url(/assets/img/lang.png);
    margin: auto;
}

header .i-uni6 {
    background-image: url(/assets/img/favicon.ico);
}

header .Navbar-modal {
    position: fixed;
    top: 0;
    right: -100%;
    bottom: 0;
    width: 100%;
    max-width: 360px;
    overflow: hidden;
    transition: right .5s linear;
    z-index: 102;
}

header .Navbar-modalContent {
    position: relative;
    margin-left: 40px;
    height: 100%;
    background-color: #151a23;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

header .Navbar-modalHeader {
    position: relative;
    height: 45px;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
}

header .Navbar-modalClose {
    position: relative;
    height: 100%;
    width: 45px;
    margin-right: 0;
    cursor: pointer;
    float: right;
}

header .Navbar-modalClose:before {
    content: '×';
    display: block;
    font-size: 30px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    line-height: 45px;
    text-align: center;
    color: #0e86ca;
}

header .Navbar-modalRow {
    position: relative;
    padding: 10px 15px;
    line-height: 25px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
}

header .Navbar-modalRowIco {
    padding-left: 45px;
}

header .Navbar-modalRowIcoImg {
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    width: 30px;
    height: 30px;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
}

header .Navbar-modalMaskShow {
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
}

header .Navbar-modalShow {
    right: 0;
}

header .Navbar-modalRow .lang {
    float: none;
    width: 100%;
    margin: 0;
    line-height: 35px;
    height: 35px;
}

header .lang {
    display: block;
    margin-top: 7px;
    float: right;
    height: 30px;
    margin-left: 20px;
    padding: 0 10px;
    line-height: 30px;
    background-color: #1c232f;
    border: 1px solid #161c25;
    color: #aaa;
    cursor: pointer;
}

header .Navbar-modalRow .tn-uni-btn {
    width: 100%;
    margin: 5px 0;
    height: 60px;
    padding: 5px;
}

header .tn-uni-btn {
    width: auto;
    float: none;
    margin-right: 0;
    position: relative;
    display: block;
    margin-bottom: 10px;
    height: 100px;
    line-height: 20px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    background-color: rgba(0, 0, 0, .1);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, .1);
    transition: all .5s linear;
}

header .tn-uni-btn:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, .3);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, .2);
}

header .Navbar-modalRow .tn-uni-ico {
    height: 48px;
    width: 48px;
}

header .tn-uni-btn span {
    display: block;
}

header .Navbar-modalRow span {
    color: rgba(255, 255, 255, .7);
}

header .tn-uni-ico {
    position: absolute;
    left: 1px;
    top: 1px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 98px;
    width: 98px;
    margin: 5px;
}

header a {
    color: #fff;
    text-decoration: none;
}

header .Navbar-modalRow .tn-uni-name,
.Navbar-modalRow .tn-uni-ver,
.Navbar-modalRow .tn-uni-lastin {
    margin-left: 65px;
}

header .tn-uni-ver {
    margin-left: 105px;
    text-align: left;
    color: #999 !important;
    font-size: 13px;
}

header .tn-uni-name {
    margin-left: 105px;
    text-align: left;
    color: #ccc !important;
}

@media only screen and (max-width: 1100px) {
    header {
        width: 94%;
    }

    header .tn-uni-btn {
        width: auto;
        float: none;
        margin-right: 0;
    }
}

@media screen and (max-width: 685px) {
    header .language-dropdown {
        display: none;
    }

    header .Navbar-modalOpen {
        display: block;
    }

    header .header-menus {
        display: none;
    }

    header .tn-uni-btn {
        width: auto;
        float: none;
        margin-right: 0;
    }
}

@media only screen and (max-width: 600px) {
    header .language-dropdown .dropdown-content ul {
        grid-template-columns: repeat(2, minmax(120px, 1fr));
    }
}

@media only screen and (max-width: 500px) {
    header .language-dropdown .dropdown-content ul {
        grid-template-columns: 1fr;
    }
}

#top_new_year .top_gift_mid{
	position:absolute;
	top:55px;
	background:url(/assets/img/top_new_year_mid.png) repeat-x;
	left:0px;
	right:0px;
	height:21px;
}
#top_new_year .top_gift_left{
	position:absolute;
	display:none;
}
#top_new_year .top_gift_right{
	position:absolute;
	display:none;
}