.acs-attack-results-main {
    margin-top: 5px;
}

.acs-attack-results {
    display: flex;
}

.acs-attack-results .acs-attack-details-panel {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2.5px;
    width: 100%;
}

.acs-attack-results .acs-attack-icon {
    width: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--main-panel-txt-color);
    font-size: 18px;
}

.acs-attack-results .acs-report-details {
    width: calc(100% / 5);
    padding: 2.5px;
    transition-duration: .5s;
}

.acs-attack-results .acs-report-details .card {
    background-color: var(--main-panel-bg-color);
    color: var(--main-panel-txt-color);
    font-size: 13px;
    padding: 5px 10px;
    border-radius: 5px;
}

.acs-attack-results .acs-report-details .card .label {
    text-align: center;
    margin-bottom: 5px;
}

.acs-attack-results .acs-report-details .card .input-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* Center vertically on small screens */
}

.acs-attack-results .acs-report-details .card .text-input {
    text-align: center;
    width: 100%;
    width: 120px;
    height: 15px;
    font-size: 13px;
}

@media (max-width: 1100px) {
    .acs-attack-results .acs-report-details .card .input-row {
        flex-direction: column;
        text-align: center;
        align-items: center;
        /* Center vertically on small screens */
    }

    .acs-attack-results .acs-report-details {
        width: calc(100% / 4);
    }
}

@media (max-width: 768px) {
    .acs-attack-results .acs-attack-icon {
        display: none;
    }

    .acs-attack-results .acs-report-details .card .text-input {
        width: 100%;
    }
}

@media (max-width: 1000px) {
    .acs-attack-results .acs-report-details {
        width: calc(100% / 4);
    }
}

@media (max-width: 650px) {
    .acs-attack-results .acs-report-details {
      width: calc(100% / 3);
    }
  }

@media (max-width: 37.5em) {
    .acs-attack-results .acs-report-details {
        width: calc(100% / 2);
    }
} 

@media (max-width: 300px) {
    .acs-attack-results .acs-report-details {
        width: 100%;
    }
}