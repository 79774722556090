#lanx-calc-panel {
    margin: 20px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

#lanx-calc-form {
    background: #000e16;
    width: 100%;
    border-radius: 7px;
    box-shadow: 0 0 10px rgba(0,0,0,.6);
    display: block;
    margin-top: 15px;
    max-width: 60%;
}

#lanx-calc-form .container {
    padding: 10px;
}

#lanx-calc-form .container .bordered-panel{
    border: 1px solid #a6c9e2;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

#lanx-calc-form .container .input-table {
    width: 100%;
    margin: 0 auto;
}

#lanx-calc-form .container .input-field,
#lanx-calc-form .container .select-field {
    width: 100%;
    border-radius: 3px;
    padding: 1.2%;
    background-color: #00060d;
    color: var(--main-panel-txt-color);
    border: 1px solid #081938;
    text-align: center;
}

#lanx-calc-form .container .input-field.invalid,
#lanx-calc-form .container .select-field.invalid {
    border-color: red;
}

#lanx-calc-form .container .select-field {
    width: 100%!important;
}

#lanx-calc-form .container .checkbox-field {
    width: 15px;
}

#lanx-calc-form .container .section-title {
    cursor: default;
}

#lanx-calc-form .container .label-cell {
    width: 150px; /* Adjust the width as needed */
    text-align: left;
    padding-right: 10px; /* Add some spacing between label and input */
}

#lanx-calc-form .container .lanx-calc-btn {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    margin-top: 20px;
}

#lanx-calc-form .container .lanx-calc-btn .btn {
    margin-top: 0;
    color: #fff;
    cursor: pointer;
    color: #f1f1f1;
    padding: 10px 20px;
    margin-left: 3%;
    font-weight: 300;
    line-height: 1.3;
    font-size: 15px;
    margin-bottom: 5px;
    background-image: -webkit-linear-gradient(top,#050f21,#050e1e);
    border: 1px solid #081938;
    background-size: contain;
    transition-duration: .5s
}

#lanx-calc-form .container .lanx-calc-btn .btn .fas {
    margin-right: 5px;
}

@media (max-width: 768px) {
    #lanx-calc-form {
        max-width: 100%;
    }

    #lanx-calc-form .container .input-table {
        width: 100%;
        margin: 0 auto;
    }

    #lanx-calc-form .container .checkbox-field {
        width: auto;
    }

    #lanx-calc-form .container .label-cell {
        padding-right: 0; /* No right padding for labels */
    }
}