footer {
	color: #fff;
	text-align: center;
	padding: 10px 0;
	max-width: 1100px;
	margin: 0 auto;
}

footer a {
	color: #fff;
	text-decoration: none;
	border-bottom: 1px solid #fff;
	transition: border-bottom-color 0.3s;
}

footer a:hover {
	border-bottom-color: transparent;
}

@media only screen and (max-width: 1100px) {
	footer {
		width: 94%;
	}
}

@media only screen and (max-width: 600px) {
    /* Adjust the screen width breakpoint as needed */
    footer a {
        display: inline-block; /* Display links inline for larger screens */
        margin-bottom: 5px; /* Remove margin for horizontal alignment */
    }
}
