.api-keys-input {
    display: flex;
    flex-wrap: wrap; /* Allow elements to wrap to the next line when there's not enough space */
    justify-content: space-between;
    margin-bottom: 5px;
}

.api-keys-input .text-input {
    width: 100%;
    background-color: #00060d;
    color: var(--main-panel-txt-color);
    margin-bottom: 5px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #081938;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}


.api-keys-input button {
    width: calc(25% - 5px);
    font-size: 15px;
    margin-bottom: 5px;
    background-image: -webkit-linear-gradient(top,#050f21,#050e1e);
    border: 1px solid #081938;
    background-size: contain;
    transition-duration: .5s;
}

.api-keys-input button .fas {
    margin-right: 5px;
}

.api-keys-input button .fa-spinner {
    animation: loading-spinner 2s linear infinite;
}

@keyframes loading-spinner {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.api-key-validity-message {
    color: var(--main-invalid-message-color);
    font-size: 15px;
    padding: 10px;
    background: var(--main-invalid-key-bg-color);
    width: 100%;
    margin: 5px 0px;
}

/* Add a media query for smaller screens */
@media (max-width: 768px) {
    .api-keys-input button {
        width: calc(50% - 5px);
    }
}