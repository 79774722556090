@import url(https://fonts.googleapis.com/css?family=Yantramanav:400,300,100&subset=latin,latin-ext);
header {
    color: #fff;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
}

header::before {
    content: "";
    background-color: #000e16;
    /* Set your desired background color */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: -1;
    /* Place it behind the header content */
}

header .logo {
    display: flex;
    align-items: center;
}

header .logo img {
    height: auto;
    margin-right: 10px;
}

header .logo span {
    display: flex;
    flex-direction: column;
}

header .logo span .part1 {
    color: #ccc;
    font-size: 18px;
    font-weight: bold;
}

header .logo span .part2 {
    font-size: 14px;
    color: #ccc;
}

header .header-menus {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    margin: auto;
    /* Adjust the gap as needed */
}

/* Style for header menu items */
header .header-menu-item {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    text-transform: uppercase;
    color: #596477;
    cursor: pointer;
}

header .header-menu-item:hover {
    text-decoration: underline;
}

header .language-dropdown {
    position: relative;
    display: inline-block;
    margin-left: auto;
    cursor: pointer;
}

header .language-dropdown select {
    display: none;
}

header .language-dropdown .dropdown-content {
    display: none;
    position: absolute;
    background-color: #555;
    z-index: 1000;
    text-align: left;
    border-radius: 5px;
    max-height: 200px;
    overflow-y: auto;
    right: 0;
    /* Align the dropdown content to the right */
    left: unset;
    /* Remove the left positioning */
}

header .language-dropdown:hover .dropdown-content {
    display: block;
}

header .language-dropdown .dropdown-content ul {
    list-style: none;
    margin: 0;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(3, minmax(120px, 1fr));
    grid-gap: 10px;
    gap: 10px;
}

header .language-dropdown .dropdown-content li {
    display: flex;
    align-items: center;
    padding: 5px;
    color: #fff;
    font-size: 14px;
}

header .language-dropdown .dropdown-content li.active-lang {
    background-color: #39495e;
}

header .language-dropdown .dropdown-content img {
    width: 32px;
    height: auto;
    margin-right: 5px;
}

header .Navbar-modalMask {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: 0;
    background-color: transparent;
    transition: background .5s linear;
    z-index: 101;
}

header .Navbar-modalOpen {
    display: none;
    position: relative;
    float: right;
    height: 45px;
    line-height: 45px;
    padding-right: 45px;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
}

header .i-menu {
    background-image: url(/assets/img/menu.png);
}

header .i-lang {
    background-image: url(/assets/img/lang.png);
    margin: auto;
}

header .i-uni6 {
    background-image: url(/assets/img/favicon.ico);
}

header .Navbar-modal {
    position: fixed;
    top: 0;
    right: -100%;
    bottom: 0;
    width: 100%;
    max-width: 360px;
    overflow: hidden;
    transition: right .5s linear;
    z-index: 102;
}

header .Navbar-modalContent {
    position: relative;
    margin-left: 40px;
    height: 100%;
    background-color: #151a23;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

header .Navbar-modalHeader {
    position: relative;
    height: 45px;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
}

header .Navbar-modalClose {
    position: relative;
    height: 100%;
    width: 45px;
    margin-right: 0;
    cursor: pointer;
    float: right;
}

header .Navbar-modalClose:before {
    content: '\D7';
    display: block;
    font-size: 30px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    line-height: 45px;
    text-align: center;
    color: #0e86ca;
}

header .Navbar-modalRow {
    position: relative;
    padding: 10px 15px;
    line-height: 25px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
}

header .Navbar-modalRowIco {
    padding-left: 45px;
}

header .Navbar-modalRowIcoImg {
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    width: 30px;
    height: 30px;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
}

header .Navbar-modalMaskShow {
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
}

header .Navbar-modalShow {
    right: 0;
}

header .Navbar-modalRow .lang {
    float: none;
    width: 100%;
    margin: 0;
    line-height: 35px;
    height: 35px;
}

header .lang {
    display: block;
    margin-top: 7px;
    float: right;
    height: 30px;
    margin-left: 20px;
    padding: 0 10px;
    line-height: 30px;
    background-color: #1c232f;
    border: 1px solid #161c25;
    color: #aaa;
    cursor: pointer;
}

header .Navbar-modalRow .tn-uni-btn {
    width: 100%;
    margin: 5px 0;
    height: 60px;
    padding: 5px;
}

header .tn-uni-btn {
    width: auto;
    float: none;
    margin-right: 0;
    position: relative;
    display: block;
    margin-bottom: 10px;
    height: 100px;
    line-height: 20px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    background-color: rgba(0, 0, 0, .1);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, .1);
    transition: all .5s linear;
}

header .tn-uni-btn:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, .3);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, .2);
}

header .Navbar-modalRow .tn-uni-ico {
    height: 48px;
    width: 48px;
}

header .tn-uni-btn span {
    display: block;
}

header .Navbar-modalRow span {
    color: rgba(255, 255, 255, .7);
}

header .tn-uni-ico {
    position: absolute;
    left: 1px;
    top: 1px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 98px;
    width: 98px;
    margin: 5px;
}

header a {
    color: #fff;
    text-decoration: none;
}

header .Navbar-modalRow .tn-uni-name,
.Navbar-modalRow .tn-uni-ver,
.Navbar-modalRow .tn-uni-lastin {
    margin-left: 65px;
}

header .tn-uni-ver {
    margin-left: 105px;
    text-align: left;
    color: #999 !important;
    font-size: 13px;
}

header .tn-uni-name {
    margin-left: 105px;
    text-align: left;
    color: #ccc !important;
}

@media only screen and (max-width: 1100px) {
    header {
        width: 94%;
    }

    header .tn-uni-btn {
        width: auto;
        float: none;
        margin-right: 0;
    }
}

@media screen and (max-width: 685px) {
    header .language-dropdown {
        display: none;
    }

    header .Navbar-modalOpen {
        display: block;
    }

    header .header-menus {
        display: none;
    }

    header .tn-uni-btn {
        width: auto;
        float: none;
        margin-right: 0;
    }
}

@media only screen and (max-width: 600px) {
    header .language-dropdown .dropdown-content ul {
        grid-template-columns: repeat(2, minmax(120px, 1fr));
    }
}

@media only screen and (max-width: 500px) {
    header .language-dropdown .dropdown-content ul {
        grid-template-columns: 1fr;
    }
}

#top_new_year .top_gift_mid{
	position:absolute;
	top:55px;
	background:url(/assets/img/top_new_year_mid.png) repeat-x;
	left:0px;
	right:0px;
	height:21px;
}
#top_new_year .top_gift_left{
	position:absolute;
	display:none;
}
#top_new_year .top_gift_right{
	position:absolute;
	display:none;
}
.text-input {
    border-radius: 5px;
    border: none;
    color: var(--main-input-txt-color);;
    background-color: var(--main-input-bg-color);
    padding: 0px 10px;
}

.text-input.invalid{
    background-color: var(--main-invalid-bg-color);
    color: var(--main-invalid-txt-color);
}

.text-input:focus{
    outline: none;
}
.button{
    color: white;
    background-color: black;
    padding: 0px 10px;
    border-radius: 5px;
    height: 35px;
    border: none;
    cursor: pointer;
}

.button:focus{
    outline: none;
}
.autocompleteTextBox__items {
    padding: 1px;
    border-radius: 4px;
    background-color: #00060d;
    min-height: 31px;
}

.autocompleteTextBoxNoItems__text {
    display: inline-block;
    margin: 4px 10px;
    padding: 5px 10px;
    line-height: 28px;
    vertical-align: bottom;
    background-color: #ebecf0;
}
.autocompleteTextBox .autocompleteTextBoxItem {
    display: inline-block;
    margin: 4px;
}
.autocompleteTextBox .autocompleteTextBoxItem__text {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 1px solid lightblue;
}
.autocompleteTextBox .autocompleteTextBoxItem__text, .autocompleteTextBox .autocompleteTextBoxItem__remove, .autocompleteTextBox .autocompleteTextBoxItem .autocompleteTextBoxAddBtn {
    padding: 5px 10px;
    /* height: 40px; */
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    vertical-align: bottom;
    color: #f5a623;
}
.autocompleteTextBox .autocompleteTextBoxItem__remove {
    background-color: gainsboro;
    cursor: pointer;
    fill: grey;
    border: 1px solid #c8c8c8;
    padding: 5px;
    outline: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
@media (hover: hover) {
    .autocompleteTextBox .autocompleteTextBoxItem__remove:hover {
        background-color: #c8c8c8;
   }
}
.autocompleteTextBox .autocompleteTextBoxItem .autocompleteTextBoxAddBtn {
    border: none;
    width: 40px;
    font-family: "Open Sans";
    margin: 0;
    font-size: 30px;
    background-color: gainsboro;
    padding: 0 10px;
    color: grey;
    border: 1px solid #c8c8c8;
    border-radius: 4px;
}
@media (hover: hover) {
    .autocompleteTextBox .autocompleteTextBoxItem .autocompleteTextBoxAddBtn {
        display: none;
   }
}
.autocompleteTextBox .autocompleteTextBoxNewItem {
    display: block;
    position: relative;
    margin: 4px;
}
.autocompleteTextBox .autocompleteTextBoxNewItem__container {
    position: absolute;
    border-radius: 4px;
    width: 100%;
}
.autocompleteTextBox .autocompleteTextBoxNewItem__text {
    min-width: 150px;
    padding: 10px;
    outline: none;
    background-color: lightblue;
    /*height: 40px;*/
    box-sizing: border-box;
}
.autocompleteTextBoxNewItem__text.warning {
    border: 1px solid red; /* Red border for warning */
}
.autocompleteTextBox .autocompleteTextBoxNewItem__list {
    position: absolute;
    list-style-type: none;
    padding: 0;
    width: 100%;
    background-color: #00060d;
    margin: 0;
    box-sizing: border-box;
    border-top: solid 1px darkgray;
    max-height: 230px;
    overflow: hidden;
    overflow-y: scroll;
    z-index: 999999;
}
.autocompleteTextBox .autocompleteTextBoxNewItem__listitem {
    padding: 8px 10px;
    cursor: pointer;
}
@media (hover: hover) {
    .autocompleteTextBox .autocompleteTextBoxNewItem__listitem:hover {
        background-color: #8ec9dc;
   }
}
.autocompleteTextBox .hidden {
    display: none;
}

.cr-input-label {
    margin-bottom: 10px;
    color: #f5a623;
    /* Custom label color */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    font-size: 2em;
    font-weight: 300;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 1);
}

.cr-input-label span {
    font-size: 2.6em;
    float: left;
}

.cr-converter-app .menu {
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    width: 200px;
    padding-right: 10px;
}

.cr-converter-app ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.cr-converter-app ul li {
    text-align: left;
    margin-bottom: 15px;
}

.cr-converter-app ul li a {
    display: block;
    color: #f1f1f1;
    background-image: -webkit-linear-gradient(top, #050f21, #050e1e);
    box-shadow: 0 0 10px #0009;
    border-radius: 7px;
    text-decoration: none;
    font-size: 0.6em;
    padding: 3%;
    border: 1px solid #081938;
    background-size: contain;
}

#input-wrapper {
    display: flex;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    border-radius: 10px;
}

#input-wrapper input[type="text"] {
    flex: 1 1;
    padding: 10px;
    font-size: 16px;
    background-color: #00060d;
    color: var(--main-panel-txt-color);
    border: 1px solid #081938;
    border-radius: 2px;
}

.api-key-error-message {
    padding: 5px;
    height: 32px;
    line-height: 32px;
    border-bottom: 1px solid;
    border-radius: 3px;
    border-color: #d5684d;
    font-size: 16px;
    background: var(--main-invalid-key-bg-color);
    margin: 5px 0px;
}

#cr-form {
    margin: 20px 15px;
}

.cr-buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    margin-top: 20px;
}

.cr-button {
    margin-top: 0;
    margin-left: 10px;
    color: white;
    cursor: pointer;
    color: #f1f1f1;
    padding: 10px 20px;
    margin-left: 3%;
    font-weight: 300;
    line-height: 1.3;
    font-size: 15px;
    margin-bottom: 5px;
    background-image: -webkit-linear-gradient(top, #050f21, #050e1e);
    border: 1px solid #081938;
    background-size: contain;
    transition-duration: .5s;
}

#cr-form #cr-options {
    background: #000e16;
    width: 100%;
    border-radius: 7px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    display: block;
    margin-top: 15px;
}

#cr-form .cr-options-container {
    padding: 0 2% 2%;
}

#cr-form #cr-options-top-container {
    padding: 2%;
}

#cr-form #cr-options-top-container {
    padding: 2%;
}

#cr-form #cr-options-top-container label {
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
}

#cr-form #cr-options-top-container select {
    width: 100% !important;
    box-sizing: border-box;
}

#cr-form #cr-options-top-container #cr-report-title {
    width: 49%;
}

#cr-form #cr-options-top-container #cr-report-title input {
    padding: 1.5%;
    width: 97%;
}

#cr-form #cr-options-top-container #cr-report-title #cr-report-title-legend {
    float: right;
    color: #fff;
}

#cr-form .cr-options-container {
    padding: 0 2% 2%;
}

#cr-form #cr-options-container {}

#cr-form #cr-options .cr-options-box {
    float: left;
    width: 23.5%;
    padding: 1%;
}

#cr-form #cr-options .cr-options-box:first-child {
    padding-left: 0;
}

#cr-form #cr-options .cr-options-box:last-child {
    padding-right: 0;
}

#cr-form #cr-options .cr-options-box h4 {
    margin: 0;
}

#cr-form #cr-options .cr-options-box select,
#cr-form #cr-options .cr-options-box input[type="text"],
#cr-form #cr-options .cr-options-box textarea {
    border-radius: 3px;
    padding: 3%;
    width: 94%;
    border: 0;
    background-color: #00060d;
    color: var(--main-panel-txt-color);
    border: 1px solid #081938;
}

.input-invalid {
    border: 1px solid red;
}

#cr-form #cr-options #cr-options-checkboxes {}

#cr-form #cr-options #cr-options-checkboxes label {
    display: block;
    clear: both;
    margin-top: 10px;
    position: relative;
    padding-right: 20px;
}

#cr-form #cr-options #cr-options-checkboxes label input {
    position: absolute;
    top: 0;
    right: 0;
}

#cr-form #cr-options .cr-option-add {
    margin-top: 10px;
    color: #fff;
    display: inline-block;
}

#cr-form #cr-options #cr-options-raids {}

#cr-form #cr-options .cr-options-player input[type="text"],
#cr-form #cr-options #cr-options-raids input[type="text"] {
    margin-top: 10px;
}

#cr-form #cr-options .cr-options-player {}

#cr-form #cr-options .cr-options-player label, textarea {
    display: block;
}

#cr-form #cr-options .cr-options-player label {
    padding: 10px 0 0;
}

#cr-form #cr-options .cr-options-player textarea {}

#cr-form.cr-opt-active #cr-convert #cr-gotdebris-auto {
    display: none;
}

#cr-form.cr-opt-active #cr-convert:hover {
    padding-bottom: 1.2%;
    border-radius: 7px;
}

#cr-form #cr-convert::before {
    content: "\AB";
}

#cr-form #cr-options-actions {
    padding: 2% 0 0;
}

#cr-form #cr-options-actions label:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

#cr-form #cr-options-actions label {
    padding: 2%;
    display: inline-block;
    border: 1px solid #39495e;
    cursor: pointer;
}

#cr-form button .fas {
    margin-right: 5px;
}

#report-wrapper h2 {
    font-size: 1.8em;
    margin-top: 0;
}

#report-wrapper #report-title h3 {
    margin-top: 0;
}

#report-wrapper h3 {
    font-size: 1.5em;
    font-weight: 300;
}

#report-wrapper #report-share input, #report-wrapper #report-title input, #report-wrapper #report-bbcode textarea {
    padding: 1%;
    font-size: 1.1em;
    border-radius: 5px;
    width: 98%;
    border: 0;
    background-color: #eee;
    color: #444;
    font-family: Yantramanav, Helvetica, sans-serif;
}

#report-wrapper #report-bbcode textarea {
    height: 150px;
}

#report-wrapper #report-social-share {
    padding-top: 20px;
}

#report-wrapper #report-preview #report-preview-html {
    padding: 10px;
    background-color: #06090E;
    border-radius: 10px;
}

#report-wrapper #report-preview #report-preview-html pre {
    margin: 0;
    font-family: inherit;
    white-space: pre-line;
	color: inherit;
    font-size: inherit;
}

#report-wrapper #report-preview #report-preview-html pre img {
    height: auto !important;
    max-width: 100%;
}

#report-wrapper #report-social-share ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
}


#report-wrapper #report-social-share ul li:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-left: 0;
}

#report-wrapper #report-social-share ul li:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

#report-wrapper #report-social-share ul li {
    position: relative;
    display: inline-block;
    width: 25%;
    flex: 1 0 auto;
    box-sizing: border-box;
    padding: 1%;
    font-size: 1.1em;
    background-color: #39495e;
    text-align: center;
    cursor: pointer;
    border-left: 1px solid #06090E;
}

#report-wrapper #report-preview #report-preview-html a {
    color: #ccc;
}

#report-wrapper #report-social-share ul li #share-public {
    position: absolute;
    padding: 10px;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #39495e;
    border: 1px solid rgba(255,255,255,0.6);
    box-sizing: border-box;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

#report-wrapper #report-social-share ul li #share-public #share-public-yes {
    padding: 5px;
    border-radius: 5px;
    background-color: #06090E;
}

#report-wrapper #report-social-share ul li #share-public a {
    color: #fff;
    display: block;
    text-decoration: none;
}

#report-wrapper #report-social-share ul li #share-public #share-public-no {
    padding: 5px;
    margin-top: 10px;
}

#report-wrapper #report-share img {
    margin-top: 20px;
    max-width: 500px;
    display: block;
}

.hint {
    font-size: 0.7em;
    font-style: italic;
    vertical-align: middle;
    color: #ddd;
}

.hint::before {
    content: "(";
}

.hint::after {
    content: ")";
}

@media (max-width: 50em) {

    #cr-form #cr-options .cr-options-box:nth-child(1n+3) {
        padding-top: 3%;
    }

    #cr-form #cr-options .cr-options-box:nth-child(2n+1) {
        padding-right: 3%;
    }

    #cr-form #cr-options .cr-options-box {
        float: none;
        display: inline-block;
        width: 47%;
        padding: 0%;
        vertical-align: top;
    }

    #cr-form #cr-options-top-container #cr-report-title {
        width: 97%;
        padding-right: 0;
    }

    #report-players .report-players-block {
        width: 44%;
    }

    #report-players #report-versus {
        display: none;
    }
}

@media (max-width: 37.5em) {
    #report-players h3 {
        text-align: center;
    }

    #report-players .report-players-block {
        width: 96%;
        padding: 2%;
        float: none;
        margin-bottom: 15px;
    }

    #report-players .report-players-block ul {
        padding: 2%;
    }

    #cr-form #cr-options .cr-options-box:first-child {
        padding-top: 1% !important;
    }

    #cr-form #cr-options .cr-options-box {
        float: none;
        display: block;
        width: 98%;
        padding: 4% 1% 1% !important;
        vertical-align: top;
    }

    .cr-converter-app .menu {
        display: none;
    }
}
#page-report * {
    box-sizing: unset;
}

#report-content-wrapper {
    padding: 1%;
    background: #1E2B39;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-top: 20px;
}

#report-content {
    padding: 10px;
    background-color: #06090E;
    border-radius: 5px;
}

#report-header .menu {
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    width: 200px;
}

#report-header ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#report-header ul li {
    text-align: left;
    margin-bottom: 15px;
}

#report-header ul li a {
    display: block;
    color: #f1f1f1;
    background-image: -webkit-linear-gradient(top, #050f21, #050e1e);
    box-shadow: 0 0 10px #0009;
    border-radius: 7px;
    text-decoration: none;
    font-size: 1.3em;
    padding: 3%;
    border: 1px solid #081938;
    background-size: contain;
}

#report-content pre {
    margin: 0;
    font-family: inherit;
    white-space: pre-line;
}

#report-content pre img {
    height: auto !important;
    max-width: 100%;
}

#report-content .topraider {
    line-height: 1.2;
}

#report-content a {
    color: #ccc;
}

#report-players .report-players-block .report-players-summary {
    margin-top: 10px;
    font-size: 1.7em;
}

#report-players .report-players-block .report-summary-positive {
    color: rgb(63, 255, 63);
}

#report-players .report-players-block .report-summary-negative {
    color: rgb(255, 51, 51);
}

#report-players .report-players-block {
    width: 36%;
    padding: 2%;
    background: #1E2B39;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

#report-players #report-attackers {
    float: left;
}

#report-players #report-defenders {
    float: right;
}

#report-players #report-versus {
    width: 10%;
    margin: 0 auto;
    text-align: center;
    font-size: 3em;
    font-weight: normal;
}

#report-players .report-players-block h4 {
    margin: 0;
    font-size: 1.7em;
}

#report-players .report-players-block ul {
    margin: 10px 0 0;
    padding: 0;
    list-style: none;
    background-color: #06090E;
    padding: 5%;
    border-radius: 5px;
}

#report-players .report-players-block ul li {
    font-size: 1.2em;
    margin-bottom: 5px;
}

#report-players .report-players-block ul li:last-child {
    margin-bottom: 0;
}

.subtitle {
    font-size: 4em;
    font-weight: 100;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 1);
}

#report-players h3 span {
    font-weight: 100;
    float: right;
}

.react-loader {
    color: #fff;
    height: 64px;
    width: 64px;
    margin: auto;
}

.options-panel {
    background-color: #1e2b39;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-top: 20px;
    transition: max-height 0.3s ease;
    /* max-height: 20px; */
    overflow: hidden;
}

.options-panel.expanded {
    max-height: 200px;
    /* Adjust the max height as needed */
}

.options-panel-header {
    font-weight: bold;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#cr-options {
    background-color: #06090e;
    border-radius: 5px;
    padding: 10px;
    margin-top: 5px;
}

#cr-options select {
    background-color: #00060d;
    border: 1px solid #081938;
    border-radius: 3px;
    color: var(--main-panel-txt-color);
    padding: 5px;
}

.options-panel-icon {
    font-size: 1.2em;
}

@media (max-width: 50em) {
    #report-players .report-players-block {
        width: 44%;
    }

    #report-players #report-versus {
        display: none;
    }
}

@media (max-width: 37.5em) {
    #report-players h3 {
        text-align: center;
    }

    #report-players .report-players-block {
        width: 96%;
        padding: 2%;
        float: none;
        margin-bottom: 15px;
    }

    #report-players .report-players-block ul {
        padding: 2%;
    }

    .options-panel {
        margin-top: 0px;
    }

    #report-header .menu {
        display: none;
    }
}
/* table.ally_ranks {
    border-collapse: collapse;
    background: 0 0;
    border-color: #000813;
    max-width: 100% !important;
    width: 100% !important;
} */

table.ally_ranks, div.pagination {
    border-collapse: separate; /* Change to separate */
    border-spacing: 0; /* Add border spacing */
    background: #000e16;
    border: 1px solid #000;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px #142c52, inset 0 -1px 0 rgba(63,59,113,.2), 0 9px 16px 0 rgba(0,0,0,.3), 0 4px 3px 0 rgba(0,0,0,.3), 0 0 0 1px #040f1f;
    padding: 10px;
    width: 100%;
}

/* Adjust header style */
table.ally_ranks th {
    text-align: center;
    padding: 8px; /* Increase padding */
}

/* Adjust cell style */
table.ally_ranks td {
    text-align: center;
    padding: 8px; /* Increase padding */
}

/* Adjust anchor style */
.classstat5 {
    color: #fff;
    text-decoration: none;
}

/* Remove unwanted style for image */
.classstat1 img {
    height: 16px;
}

#ally_content {
    overflow: hidden;
    position: relative;
    margin: 20px 15px;
}

.barraclass {
    height: 12px;
    border: 1px solid #000;
    border-radius: 3px;
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 2;
    background: rgba(0, 0, 0, .25);
    box-shadow: inset 0 1px 2px rgba(0,0,0,.25), 0 1px rgba(255,255,255,.08);
    line-height: 4px;
    padding: 1px;
    font-size: 11px;
    width: 100%;
    float: left;
    text-decoration: none;
    text-shadow: 0 0 21px rgba(223,206,228,.5), 0 -1px 0 #162e3c;
    vertical-align: unset;
    margin: 7px 0 7px 0;
}

.barraclass th {
    color: #ccc;
    background: 0 0
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.classificabarra {
    height: auto; /* Change to auto height */
    border: 1px solid #000;
    border-radius: 3px;
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 2;
    background: rgba(0, 0, 0, .25);
    box-shadow: inset 0 1px 2px rgba(0,0,0,.25), 0 1px rgba(255,255,255,.08);
    font-size: 12px; /* Adjust font size */
    text-decoration: none;
    text-shadow: 0 0 21px rgba(223,206,228,.5), 0 -1px 0 #162e3c;
    vertical-align: middle; /* Change to middle */
    margin: 7px 0; /* Remove left and right margin */
    padding: 5px; /* Increase padding */
}

.classificabarra.line {
    cursor: pointer;
    background: unset;   
}

.classificabarra.line:hover {
    background: #0a172b;
    transition: all .2s ease-in;
}

.barraclass th {
    color: #ccc;
    background: none; /* Remove background */
}

.tabstatistica td {
    border: none;
    color: #a9a9a9;
    text-align: center;
    padding: 0 10px;
    border-color: #000;
    line-height: 23px;
}

td.report-summary-positive {
    color: rgb(63, 255, 63);
}

td.report-summary-negative {
    color: rgb(255, 51, 51);
}

.top-type-select {
    background-color: #00060d;
    border: 1px solid #081938;
    border-radius: 3px;
    color: var(--main-panel-txt-color);
    padding: 5px;
}

#filter-community-wrapper {
    position: relative;
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
}

#filter-community-wrapper #filter-community {
    background: #39495e;
    padding: 7px 7px 6px;
    border-radius: 3px;
}

#filter-community-wrapper ul {
    display: none;
    position: absolute;
    list-style: none;
    background: #39495e;
    height: 200px;
    overflow-y: auto;
    margin: 0;
    padding: 7px 30px 7px 7px;
    box-shadow: 0 2px 10px #000c;
    z-index: 10;
}

#filter-community-wrapper:hover ul{display:block}

#filter-community-wrapper ul li:first-child {
    margin-top: 0;
}

#filter-community-wrapper ul li {
    margin-top: 5px;
    cursor: pointer;
}
.panda {
    display: none;
}

.active,
.active2 {
    color: #929292 !important;
    font-weight: 600;
}

#landing {
    min-height: 400px;
    width: 100%;
    background-size: cover;
    text-align: center;
}

.cover {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 5vh;
}

.cont-1 {
    width: 100%;
}

.pink {
    color: #FFACAC;
}

.cont-1 h1 {
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-weight: 800;
    font-size: 42px;
    margin: 1vh 0;
}

.nav-logo {
    height: 5vh;
    width: auto;
}

.option-tag {
    display: inline-block;
    padding: 4px 8px;
    margin: 4px;
    border-radius: 4px;
    font-size: 14px;
}

.required-option {
    background-color: #ffcccc; /* Light red background for required options */
}

.optional-option {
    background-color: #ccffcc; /* Light green background for optional options */
}

.cont-1 p {
    margin-top: -1vh;
    margin-bottom: 7vh;
    color: #7e7d7d;
    font-size: 25px;
    font-weight: 300;
}

#logodiscord {
    width: 200px;
}

#about {
    width: 100%;
    height: auto;
    padding: 12vh 0;
    background: #121316;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: white;
}

#about p {
    width: 70%;
    font-size: 17px;
    color: grey;
    text-align: center;
}

#about h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    width: 90%;
    text-align: center;
    font-size: 42px;
}

.row {
    display: flex;
    flex-direction: row;
    margin: 3vh 0;
}

.row i {
    margin: 1vh;
    cursor: pointer;
    color: rgb(214, 214, 214);
    font-size: 40px;
}

#collaborator {
    height: 100vh;
    width: 100%;
    background: url('/assets/img/discord/collab.png');
    background-size: cover;
    background-position: center;
}

#collaborator h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 42px;
    width: 90%;
    text-align: center;
    color: white;
}

#collaborator p {
    width: 60%;
    font-size: 17px;
    color: rgb(189, 189, 189);
    text-align: center;
}

#collaborator i {
    font-size: 50px;
    color: white;
    cursor: pointer;
    margin: 2vh;
}

.btn-invite-server {
    margin: 3vh 0;
    padding: 2vh 4vh;
    border-radius: 5px;
    text-decoration: none;
    color: white;
    font-size: 16px;
    letter-spacing: 5px;
    word-spacing: 7px;
    background: rgb(149, 153, 226);
    background: linear-gradient(45deg, rgba(149, 153, 226, 1) 0%, rgba(220, 134, 128, 1) 100%);
    font-weight: 400;
}

.colab {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgb(19, 20, 24);
    background: linear-gradient(180deg, rgba(19, 20, 24, 1) 12%, rgba(255, 255, 255, 0) 100%);
}

#commands {
    width: 92%;
    padding: 0 4%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #18191D;
    box-sizing: unset;
}

#commands h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 42px;
    width: 90%;
    margin-bottom: 0 !important;
    text-align: center;
    color: white;
}

#commands p {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    text-align: center;
    width: 92%;
    margin-bottom: 5vh;
    font-weight: 600;
    margin-top: 2vh;
    color: #EEA47F;

}

.command-bg {
    width: 92%;
    padding: 17vh 4%;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    background: url();
    align-items: center;
    justify-content: center;
}

.command-img {
    width: auto;
    height: 40vh;
    align-self: flex-end;
}

.command-grid {
    width: 80%;
    display: flex !important;
    flex-wrap: wrap !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
}

.command-box {
    margin: 3vh 5%;
    display: flex;
    width: 40%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.command-p-h {
    font-size: 16px !important;
    color: rgb(236, 236, 236) !important;
    font-weight: 500 !important;
}

.command-p.legend {
    text-align: center !important;
    margin: 10px 0px 0px 00px !important;
}

.command-p {
    margin: 0 !important;
    margin-bottom: 0.5vh !important;
    font-size: 14px !important;
    color: rgb(160, 158, 158) !important;
    margin-left: 8vh !important;
    text-align: left !important;
    font-weight: 500 !important;
}



.sno-com {
    color: rgb(221, 218, 218);
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    font-size: 17px;
    margin: 0.5vh 0;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.command-orange {
    background: #EEA47F;
    color: rgb(20, 20, 31);
    border-radius: 5px;
    font-weight: 600 !important;
    font-size: 16px;
    padding: 1vh 4vh;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 1vh;
}

.footer {
    width: 92%;
    background: #121316;
    min-height: 7vh;
    padding: 1vh 4%;
    display: flex;
    flex-direction: row;
    color: rgb(233, 233, 233);
    align-items: center;
    justify-content: space-between;
}

.big-nav-show {
    display: flex !important;
}

.footer p {
    color: rgb(196, 196, 196);
}

.ficon {
    margin: 0 1vh !important;
    font-size: 20px !important;
}

#nav-tog {
    display: block;
}

#logoBot {
    margin-top: 7vh !important;
    height: 18vh;
}

.footer-logo {
    height: 7vh;
    width: auto;
}

.footer-mtop {
    margin-top: -1vh;
}

.nav-small-display {
    display: none;
    flex-direction: column;
}

.about-img {
    width: 60%;
    margin: 4vh 20%;
}

#feature-grid {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 5vh;
}

#features {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 92%;
    padding: 13vh 4%;
    background: url('/assets/img/discord/features.jpg');
    background-size: cover;
    box-sizing: unset;
}

.feature-card {
    width: 32vh;
    margin: 0 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5vh;
    border-radius: 8px;
    color: white;
}

.feature-card img {
    height: 15vh;
    width: auto;
    margin: 2vh 0;
}

.feature-card h3 {
    font-size: 24px;
    font-weight: 500;
    margin: 0.5vh 0;
    color: #EEA47F;
    font-family: 'Montserrat', sans-serif;
}

.feature-card p {
    padding: 1vh 3vh;
    background: #18191D;
    border-radius: 7px;
    margin: 0.5vh 0;
    color: #929292;
    font-family: 'Montserrat', sans-serif;
}

#features h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 42px;
    width: 90%;
    /* margin-bottom:0 !important; */
    text-align: center;
    color: white;
}

@media screen and (max-width:1250px) {
    .cont-2 img {
        height: 63vh;
    }

    #feature-grid {
        width: 90%;
    }
}

@media screen and (max-width:1100px) {
    .cont-2 img {
        height: 56vh;
    }

    .cont-1 h1 {
        font-size: 39px;
    }

    #feature-grid {
        width: 100%;
    }
}

@media screen and (max-width:1000px) {
    .cover {
        padding-top: 5vh;
        flex-direction: column;
        height: auto;
        align-items: center;
        justify-content: center;
    }

    #landing {
        overflow: hidden;
    }

    .cont-1 {
        margin-top: 0;
        width: 90%;
        height: auto;
    }

    .panda {
        display: flex;
    }

    .nav-logo {
        display: none;
    }

    .cont-2 {
        display: none;
    }

    #about p {
        width: 90%;
    }

    #collaborator p {
        width: 80%;
    }

    .command-grid {
        width: 90%;
        justify-content: space-between !important;
    }

    .command-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .about-img {
        width: 70%;
        margin: 4vh 15%;
    }
}

@media screen and (max-width:900px) {
    .command-grid {
        width: 100%;
        display: flex !important;
        flex-wrap: nowrap;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
    }

    .command-box {
        width: 80%;
        margin-right: 10%;
    }

    .nav {
        min-height: 6vh;
        height: auto;
        background: #121316;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.326);
        z-index: 200;
    }

    .nav-icon {
        display: flex;
        flex-direction: column;
        height: 4vh;
        width: 4vh;
        cursor: pointer;
        margin: 1vh 0;
        /* background:white; */
        margin-left: 4vh;
    }

    .bar-nav {
        height: 6px !important;
        margin: 3px 0;
        border-radius: 5px;
        width: 100%;
        background: rgb(44, 44, 44);
    }

    .nav-item,
    .nav-item2 {
        text-decoration: none;
        color: #4E4E4E;
        padding: 2vh 4vh;
    }

    .nav-small-display {
        display: flex;
        flex-direction: column;
    }

    #nav-tog {
        display: flex;
        flex-direction: column;
    }

    #navtoggle {
        display: none;
    }

    .big-nav-show {
        display: none !important;
    }

    .about-img {
        width: 90%;
        margin: 4vh 5%;
    }

}

@media screen and (max-width:700px) {
    .cont-2 img {
        height: auto;
        width: 90%;
    }

    #collaborator p {
        width: 90%;
        font-size: 16px;
    }

    #about p {
        font-size: 16px;
    }

    h1 {
        font-size: 37px;
    }

    .command-img {
        min-width: auto;
        width: 90%;
        min-height: auto;
        height: auto;
        align-self: flex-end;
    }
}

@media screen and (max-width:400px){
    .btn-invite-server {
        font-size: 12px;
    }
}

.select-player-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.select-player-side .label {
  color: var(--main-panel-txt-color);
  font-size: 17px;
}

.select-player-side .button {
  margin-top: 5px;
  width: 200px;
  font-size: 15px;
  margin-bottom: 5px;
  background-image: -webkit-linear-gradient(top,#050f21,#050e1e);
  border: 1px solid #081938;
  background-size: contain;
}
.acs-protected-access-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.acs-protected-access-container .card {
    background: #000e16;
    border-radius: 7px;
    box-shadow: 0 0 10px rgba(0,0,0,.6);
    display: block;
    margin-top: 15px;
    min-width: 360px;
}

.acs-protected-access-container .card .mb-0 {
    margin-bottom: 0!important;
}

.acs-protected-access-container .card .card-body {
    flex: 1 1 auto;
    padding: 1.25rem 1.25rem;
}

.acs-protected-access-container .card .card-body .text-center {
    text-align: center!important;
}

.acs-protected-access-container .card .card-body .mb-3 {
    margin-bottom: 1.25rem!important;
}

.acs-protected-access-container .card .card-body .alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.acs-protected-access-container .card .card-body .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    margin-top: 8px;
}

.acs-protected-access-container .card .card-body .text-muted {
    --text-opacity: 1;
    color: rgba(255,255,255,.75)!important;
}

.acs-protected-access-container .card .card-body .d-block {
    display: block!important;
}

.acs-protected-access-container .card .card-body .form-label {
    margin-bottom: 0.5rem;
}

.acs-protected-access-container .card .card-body label {
    display: inline-block;
}

.acs-protected-access-container .card .card-body .form-control-feedback {
    position: relative;
}

.acs-protected-access-container .card .card-body .btn {
    margin-top: 0;
    color: #fff;
    cursor: pointer;
    color: #f1f1f1;
    padding: 10px 20px;
    font-weight: 300;
    font-size: 15px;
    margin-bottom: 5px;
    background-image: -webkit-linear-gradient(top,#050f21,#050e1e);
    border: 1px solid #081938;
    background-size: contain;
    transition-duration: .5s;
}

.acs-protected-access-container .card .card-body .btn .fas {
    margin-right: 5px;
}

.acs-protected-access-container .card .card-body .w-100 {
    width: 100%!important;
}

.acs-protected-access-container .card .card-body .form-control {
    display: block;
    width: 100%;
    padding: 0.5rem 0.875rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5715;
    color: #fff;
    background-color: #2c2d33;
    background-clip: padding-box;
    border: 1px solid #4d4d51;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    box-shadow: 0 0 0 0 transparent;
    transition: border-color var(--transition-base-timer) ease-in-out,box-shadow var(--transition-base-timer) ease-in-out;
}

.acs-protected-access-container .card .card-body .text-input.invalid{
    border-color: var(--main-invalid-bg-color);
    color: var(--main-invalid-txt-color);
}


@media (max-width: 768px) {
    .acs-protected-access-container .card {
        min-width: 0;
    }
}

.api-keys-input {
    display: flex;
    flex-wrap: wrap; /* Allow elements to wrap to the next line when there's not enough space */
    justify-content: space-between;
    margin-bottom: 5px;
}

.api-keys-input .text-input {
    width: 100%;
    background-color: #00060d;
    color: var(--main-panel-txt-color);
    margin-bottom: 5px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #081938;
    border-radius: 2px;
}


.api-keys-input button {
    width: calc(25% - 5px);
    font-size: 15px;
    margin-bottom: 5px;
    background-image: -webkit-linear-gradient(top,#050f21,#050e1e);
    border: 1px solid #081938;
    background-size: contain;
    transition-duration: .5s;
}

.api-keys-input button .fas {
    margin-right: 5px;
}

.api-keys-input button .fa-spinner {
    animation: loading-spinner 2s linear infinite;
}

@keyframes loading-spinner {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.api-key-validity-message {
    color: var(--main-invalid-message-color);
    font-size: 15px;
    padding: 10px;
    background: var(--main-invalid-key-bg-color);
    width: 100%;
    margin: 5px 0px;
}

/* Add a media query for smaller screens */
@media (max-width: 768px) {
    .api-keys-input button {
        width: calc(50% - 5px);
    }
}
.custom-report-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.6);
}

.custom-report-panel .card {
  padding: 10px 10px;
  background-color: var(--main-panel-txt-color);
  color: var(--main-paneltitle-bg-color);
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
}

.custom-report-panel .card .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.custom-report-panel .card .title .fas {
  cursor: pointer;
}

.custom-report-panel .card .section {
  border-top: 1px solid black;
}

.custom-report-panel .card .save {
  display: flex;
  justify-content: center;
}

.custom-report-panel .card .section .description {
  margin-top: 10px;
  text-align: center;
}

.custom-report-panel .card .section .options {
  margin: 5px 0px;
  display: flex;
  justify-content: center;
}

.custom-report-panel .card .section .options div {
  min-width: 40px;
  height: 30px;
  display: flex;
  margin: 5px 10px;
  padding: 0px 5px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.custom-report-panel .card .section .options div .text-input {
  background-color: var(--main-paneltitle-bg-color);
  color: var(--main-paneltitle-txt-color);
  width: 100px;
  height: 30px;
  text-align: center;
  padding: 0px;
}
.custom-report-panel .card .section .options.wrap {
  flex-wrap: wrap;
}

.custom-report-panel .card .section .options div.selected {
  border: 2px solid var(--main-paneltitle-bg-color);
}

.settings-panel{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.7);;
}

.settings-panel .card{
    padding: 10px 10px;
    background-color: var(--main-panel-txt-color);
    color: var(--main-paneltitle-bg-color);
    border-radius: 5px;
    font-weight: bold;
}

.settings-panel .card .title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.settings-panel .card .title .fas{
    cursor: pointer;
}

.settings-panel .setting-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
}

.settings-panel .setting-section.convert-consumption{
    margin-top: 5px;
}

.settings-panel .setting-section .control .fas{
    font-size: 30px;
    padding-left: 40px;
    cursor: pointer;
}

.settings-panel .setting-section .control.conversion .text-input{
    width: 35px;
    padding: 0px;
    text-align: center;
    background-color:  var(--main-paneltitle-bg-color);
    color: var(--main-panel-txt-color);
}
.section-title {
  background-color: var(--main-paneltitle-bg-color);
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
  cursor: pointer;
  border-radius: 5px;
}

.section-title .fas {
  color: var(--main-paneltitle-txt-color);
  font-size: 20px;
}

.section-title .title {
  color: var(--main-paneltitle-txt-color);
  font-size: 15px;
  margin-left: 10px;
}

.acs-attack-results-main {
    margin-top: 5px;
}

.acs-attack-results {
    display: flex;
}

.acs-attack-results .acs-attack-details-panel {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2.5px;
    width: 100%;
}

.acs-attack-results .acs-attack-icon {
    width: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--main-panel-txt-color);
    font-size: 18px;
}

.acs-attack-results .acs-report-details {
    width: calc(100% / 5);
    padding: 2.5px;
    transition-duration: .5s;
}

.acs-attack-results .acs-report-details .card {
    background-color: var(--main-panel-bg-color);
    color: var(--main-panel-txt-color);
    font-size: 13px;
    padding: 5px 10px;
    border-radius: 5px;
}

.acs-attack-results .acs-report-details .card .label {
    text-align: center;
    margin-bottom: 5px;
}

.acs-attack-results .acs-report-details .card .input-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* Center vertically on small screens */
}

.acs-attack-results .acs-report-details .card .text-input {
    text-align: center;
    width: 100%;
    width: 120px;
    height: 15px;
    font-size: 13px;
}

@media (max-width: 1100px) {
    .acs-attack-results .acs-report-details .card .input-row {
        flex-direction: column;
        text-align: center;
        align-items: center;
        /* Center vertically on small screens */
    }

    .acs-attack-results .acs-report-details {
        width: calc(100% / 4);
    }
}

@media (max-width: 768px) {
    .acs-attack-results .acs-attack-icon {
        display: none;
    }

    .acs-attack-results .acs-report-details .card .text-input {
        width: 100%;
    }
}

@media (max-width: 1000px) {
    .acs-attack-results .acs-report-details {
        width: calc(100% / 4);
    }
}

@media (max-width: 650px) {
    .acs-attack-results .acs-report-details {
      width: calc(100% / 3);
    }
  }

@media (max-width: 37.5em) {
    .acs-attack-results .acs-report-details {
        width: calc(100% / 2);
    }
} 

@media (max-width: 300px) {
    .acs-attack-results .acs-report-details {
        width: 100%;
    }
}
.attack-fleet-details {
  width: 20%;
  padding: 2.5px;
}

.attack-fleet-details .card{
  background-color: var(--main-panel-bg-color);
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 13px;
  color: var(--main-panel-txt-color);
}

.attack-fleet-details .card.own{
    border-color: green;
    border-width: 2px;
    border-style: solid;
}

.attack-fleet-details .card.excluded{
  opacity: 0.4;
}

.attack-fleet-details .player-name {
  text-align: center;
  margin-bottom: 5px;
}

.attack-fleet-details .hyperspace-tech {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.attack-fleet-details .character-class{
  display: flex;
  align-items: center;
  padding: 2.5px 0px;
}

.attack-fleet-details .character-class input{
  margin: 0px 5px 0px 0px;
}

.attack-fleet-details .hyperspace-tech .text-input {
  width: 70px;
  height: 15px;
  padding: 0px;
  text-align: center;
}

.attack-fleet-details .label {
  text-align: center;
  margin: 5px 0px;
}

.attack-fleet-details .one-input-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.attack-fleet-details .one-input-info .text-input {
  width: 110px;
}

.attack-fleet-details .fleet-composition {
  display: flex;
}

.attack-fleet-details .fleet-composition .ship-name {
  width: 30%;
  font-size: 11px;
}

.attack-fleet-details .fleet-composition .ship-count {
  display: flex;
  width: 70%;
  justify-content: space-around;
  font-size: 11px;
}

.attack-fleet-details .fleet-composition .ship-count .text-input {
  width: 49%;
  height: 12px;
  border-radius: 3px;
  font-size: inherit ;
}

.attack-fleet-details .text-input {
  height: 15px;
  padding: 0px;
  text-align: center;
}
.fleet-details {
  display: flex;
  margin-top: 2.5px;
  transition-duration: .5s;
}

.fleet-details .fleet-label {
  color: var(--main-panel-txt-color);
  background-color: var(--main-panel-bg-color);
  ;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  font-size: 15px;
}

.fleet-details .fleet-label.expanded {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  background-color: transparent;
  width: 2%;
}

.fleet-details .fleeter-details-panel {
  width: 100%;
  flex-wrap: wrap;
  margin-top: 2.5px;
  display: flex;
}

.fleet-details .fleet-label .fa-caret-right {
  font-size: 20px;
  margin-bottom: 8px;
}

.fleet-details .fleet-label .fa-caret-down {
  font-size: 20px;
  margin-right: 8px;
}

.fleet-details .card .input-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Center vertically on small screens */
}

.fleet-details .card .text-input {
  text-align: center;
  width: 100%;
  width: 120px;
  height: 15px;
  font-size: 13px;
}


@media (max-width: 1100px) {
  .fleet-details .card .input-row {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .fleet-details .attack-fleet-details {
    width: calc(100% / 4);
  }
}

@media (max-width: 768px) {
  .fleet-details {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .fleet-details .fleet-label.expanded {
    width: 100%;
    writing-mode: horizontal-tb;
    transform: none;
  }

  .fleet-details .fleet-label {
    text-align: center;
    background: transparent;
  }

  .fleet-details .fleet-label .fa-caret-right,
  .fleet-details .fleet-label .fa-caret-down {
    margin-right: 8px;
    margin-bottom: 0;
  }

  .fleet-details .card .text-input {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .fleet-details .attack-fleet-details {
    width: calc(100% / 4);
  }
}

@media (max-width: 650px) {
  .fleet-details .attack-fleet-details {
    width: calc(100% / 3);
  }
}

@media (max-width: 37.5em) {
  .fleet-details .attack-fleet-details {
    width: calc(100% / 2);
  }
}

@media (max-width: 300px) {
  .fleet-details .attack-fleet-details {
      width: 100%;
  }
}
.fleet-overview-main{
    margin-top: 5px;
}

.fleet-overview{
    display: flex;
    flex-direction: column;
}
.recycler-pickups-main {
    margin-top: 5px;
}

.recycler-pickups {
    display: flex;
}

.recycler-pickups .recycle-icon {
    width: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--main-panel-txt-color);
    font-size: 18px;
}

.recycler-pickups .recyclers-details-panel {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2.5px;
    width: 100%;
}

.recycler-pickups .recycler-report-details {
    width: calc(100% / 5);
    padding: 2.5px;
    transition-duration: .5s;
}

.recycler-pickups .recycler-report-details .card {
    background-color: var(--main-panel-bg-color);
    color: var(--main-panel-txt-color);
    font-size: 13px;
    padding: 5px 10px;
    border-radius: 5px;
}

.recycler-pickups .recycler-report-details .card.own{
    border-color: green;
    border-width: 2px;
    border-style: solid;
}

.recycler-pickups .recycler-report-details .card .label {
    text-align: center;
    margin-bottom: 5px;
}

.recycler-pickups .recycler-report-details .card .input-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* Center vertically on small screens */
}

.recycler-pickups .recycler-report-details .card .text-input {
    text-align: center;
    width: 100%;
    width: 120px;
    height: 15px;
    font-size: 13px;
}

@media (max-width: 1100px) {
    .recycler-pickups .recycler-report-details .card .input-row {
        flex-direction: column;
        text-align: center;
        align-items: center;
        /* Center vertically on small screens */
    }

    .recycler-pickups .recycler-report-details {
        width: calc(100% / 4);
    }
}

@media (max-width: 768px) {
    .recycler-pickups .recycle-icon {
        display: none;
    }

    .recycler-pickups .recycler-report-details .card .text-input {
        width: 100%;
    }
}

@media (max-width: 1000px) {
    .recycler-pickups .recycler-report-details {
        width: calc(100% / 4);
    }
}

@media (max-width: 650px) {
    .recycler-pickups .recycler-report-details {
      width: calc(100% / 3);
    }
  }

@media (max-width: 37.5em) {
    .recycler-pickups .recycler-report-details {
        width: calc(100% / 2);
    }
}

@media (max-width: 300px) {
    .recycler-pickups .recycler-report-details {
        width: 100%;
    }
}
.player-totals-main {
  display: flex;
  width: 100%;
}

.player-totals-main .player-totals-label {
  color: var(--main-panel-txt-color);
  background-color: var(--main-panel-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  font-size: 15px;
  margin-top: 5px;
}

.player-totals-main .player-totals-label.expanded {
  margin-top: 0px;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  background-color: transparent;
  width: 2%;
}

.player-totals-main .player-totals-label .fa-caret-right {
  font-size: 20px;
  margin-bottom: 8px;
}

.player-totals-main .player-totals-label .fa-caret-down {
  font-size: 20px;
  margin-right: 8px;
}

.player-totals-main .player-totals {
  width: 98%;
  flex-wrap: wrap;
  justify-content: center;
}

.player-totals-main .player-totals .player-total {
  display: flex;
  width: 100%;
  padding: 2.5px;
}

.player-totals-main .player-totals .player-total .player-name {
  width: 20%;
  padding: 0px 2.5px;
}

.player-totals-main .player-totals .player-total .player-name .text {
  height: 100%;
  color: var(--main-panel-txt-color);
  background: var(--main-panel-bg-color);
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.player-totals-main .player-totals .player-total .result-resources {
  width: 80%;
  padding: 0px 2.5px;
}

.player-totals-main .player-totals .player-total .data {
  color: var(--main-panel-txt-color);
  background: var(--main-panel-bg-color);
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 10px;
  box-shadow: inset 0 1px 1px #142c52, inset 0 -1px 0 rgba(63, 59, 113, .2), 0 9px 16px 0 rgba(0, 0, 0, .3), 0 4px 3px 0 rgba(0, 0, 0, .3), 0 0 0 1px #040f1f;
  height: 100%;
}

.player-totals-main .player-totals .player-total .row {
  display: flex;
  align-items: center;
}

.player-totals-main .player-totals .player-total .row.settlement-title {
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 5px;
}

.player-totals-main .player-totals .player-total .row div {
  width: 30%;
  text-align: right;
}

.player-totals-main .player-totals .player-total .row div.green {
  color: rgb(13, 255, 0);
}

.player-totals-main .player-totals .player-total .row div.red {
  color: rgb(252, 3, 32);
}

.player-totals-main .player-totals .player-total .row div:first-child {
  text-align: left;
}

.player-totals-main .player-totals .player-total .row.title {
  margin-bottom: 5px;
}

.player-totals-main .player-totals .player-total .row .contribution-prompt {
  display: flex;
  align-items: center;
}

.player-totals-main .player-totals .player-total .row .contribution-prompt div {
  width: 50%;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;
}

.player-totals-main .player-totals .player-total .row .contribution-prompt div.settlement-full {
  color: grey;
}

.player-totals-main .player-totals .player-total .row .contribution-prompt .text-input.settlement-full {
  background-color: grey;
}

.player-totals-main .player-totals .player-total .row .contribution-prompt .text-input {
  width: 50%;
  text-align: center;
  height: 15px;
}

@media (max-width: 768px) {
  .player-totals-main {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .player-totals-main .player-totals-label {
    width: 100%;
    /* Full width */
    text-align: center;
    /* Center-align text */
    background: transparent;
  }

  .player-totals-main .player-totals {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .player-totals-main .player-totals-label.expanded {
    writing-mode: horizontal-tb;
    /* Reset text orientation */
    transform: none;
    /* Reset rotation */
    width: 100%;
  }

  .player-totals-main .player-totals-label .fa-caret-right,
  .player-totals-main .player-totals-label .fa-caret-down {
    margin-right: 8px;
    margin-bottom: 0;
    /* Reset margin */
  }
  
  .player-totals-main .player-totals .player-total {
    width: 100%;
    flex-direction: column;
    align-items: center;
    /* Full width */
  }

  .player-totals-main .player-totals .player-total .player-name {
    width: 100%;
    /* Full width */
    text-align: center;
    /* Center-align text */
  }

  .player-totals-main .player-totals .player-total .result-resources {
    width: 100%;
    padding: 2.5px;
    margin-top: 3px;
  }

  .player-totals-main .player-totals .player-total .row .contribution-prompt {
    flex-direction: column; /* Stack items vertically */
    align-items: flex-end; /* Right-align items */
  }

  .player-totals-main .player-totals .player-total .row .contribution-prompt div {
    width: 100%; /* Full width for the value */
    padding-right: 0; /* Remove padding on small screens */
    margin-top: 5px; /* Add margin between value and input */
  }

  .player-totals-main .player-totals .player-total .row .contribution-prompt .text-input {
    text-align: center; /* Center-align text */
  }
}
.result-summary {
  width: 98%;
  padding: 2.5px;
  display: flex;
}

.result-summary .result-resources{
  width: 80%;
  padding: 0px 2.5px;
}

.result-summary .result-resources .data {
  color: var(--main-panel-txt-color);
  background: var(--main-panel-bg-color);
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 10px;
  box-shadow: inset 0 1px 1px #142c52, inset 0 -1px 0 rgba(63,59,113,.2), 0 9px 16px 0 rgba(0,0,0,.3), 0 4px 3px 0 rgba(0,0,0,.3), 0 0 0 1px #040f1f;
}

.result-summary .row {
  display: flex;
  justify-content: space-around;
}

.result-summary .row div {
  width: 30%;
  text-align: right;
}

.result-summary .row div:first-child {
  text-align: left;
}

.result-summary .title {
  width: 20%;
  padding: 0px 2.5px;
}

.result-summary .title .text{
  height: 100%;
  color: var(--main-panel-txt-color);
  background: var(--main-panel-bg-color);
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .result-summary {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .result-summary .result-resources {
    width: 100%;
    padding: 0px 2.5px;
  }

  .result-summary .title {
    display: none;
  }
}
.transport-summary {
  width: 98%;
  padding: 2.5px;
  display: flex;

}

.transport-summary .result-transport {
  width: 80%;
  padding: 0px 2.5px;
}

.transport-summary .result-transport .data {
  color: var(--main-panel-txt-color);
  background: var(--main-panel-bg-color);
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 10px;
  text-align: center;
  box-shadow: inset 0 1px 1px #142c52, inset 0 -1px 0 rgba(63, 59, 113, .2), 0 9px 16px 0 rgba(0, 0, 0, .3), 0 4px 3px 0 rgba(0, 0, 0, .3), 0 0 0 1px #040f1f;
}

.transport-summary .result-transport .data>div {
  padding: 5px 0px;
}

.transport-summary .title {
  width: 20%;
  padding: 0px 2.5px;
}

.transport-summary .title .text {
  height: 100%;
  color: var(--main-panel-txt-color);
  background: var(--main-panel-bg-color);
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .transport-summary {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .transport-summary .title,
  .transport-summary .result-transport {
    width: 100%;
    margin-top: 5px;
  }
}
.result-main {
  margin-top: 5px;
}

.result-main .result {
  flex-direction: column;
  align-items: flex-end;
  margin-top: 2.5px;
}

.result-main .resources-bar {
  display: flex;
  justify-content: flex-end;
  padding: 2.5px;
  width: 98%;
}

.result-main .resources-bar .bar {
  width: 80%;
  display: flex;
  justify-content: center;
  color: var(--main-panel-txt-color);
  background: var(--main-panel-bg-color);
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 10px;
}

.result-main .resources-bar .bar div:first-child {
  text-align: left;
}

.result-main .resources-bar .bar div {
  width: 30%;
  text-align: right;
}


@media (max-width: 768px) {
  .result-main .result {
    flex-direction: column;
    align-items: center;
    /* Center-align content */
    margin-top: 2.5px;
  }

  .result-main .resources-bar {
    justify-content: space-between;
    /* Space evenly */
    padding: 2.5px;
    width: 100%;
  }

  .result-main .resources-bar .bar {
    width: 100%;
    /* Full width */
    text-align: center;
    /* Center-align text */
  }
}
.panel{
    width: 100%;
    flex-direction: column;
}
:root {
    --main-panel-bg-color: #0a1729;
    --main-panel-txt-color: rgb(255, 255, 255);
    --main-paneltitle-bg-color: #14283a;
    --main-paneltitle-txt-color: rgb(255, 255, 255);
    --main-input-bg-color: rgb(255, 255, 255, 0.9);
    --main-input-txt-color: rgb(0, 0, 0);
    --main-invalid-bg-color: rgb(255, 8, 0);
    --main-invalid-key-bg-color: rgb(255, 8, 0, 0.3);
    --main-invalid-message-color: rgb(255, 8, 0);
    --main-gain-bg-color: rgb(13, 255, 0);
    --main-loss-bg-color: rgb(252, 3, 32);
}

main .loading-main {
    display: flex;
    height: 100%;
    margin-top: 200px;
    justify-content: center;
}

main .loading-main .fas {
    animation: loading-spinner 2s linear infinite;
    color: var(--main-panel-txt-color);
    ;
    font-size: 100px;
}

main .api-key-list-item {
    width: 100%;
    background-color: var(--main-panel-bg-color);
    color: var(--main-panel-txt-color);
    margin-bottom: 5px;
    font-size: 14px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    overflow-wrap: anywhere;
}

main .api-key-list-item .fas {
    margin-right: 10px;
    cursor: pointer;
}

main .share-container {
    width: 100%;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 2.5px;
    display: flex;
}

main .share-container .share {
    display: flex;
    justify-content: flex-end;
    padding: 2.5px;
    width: 98%;
}

main .share-container .share .title {
    color: var(--main-paneltitle-txt-color);
    font-size: 15px;
    margin-left: 10px;
}

main .share-button {
    width: 100%;
    background-color: var(--main-paneltitle-bg-color);
    color: var(--main-paneltitle-txt-color);
    border-radius: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 80%;
}

main .share-container .share-content .copy-icon {
    margin-left: 10px;
    cursor: pointer;
}

/* Style the pin code */
main .share-container .share-content .pincode {
    font-size: 13px;
    color: #777;
    margin-left: 10px;
}

/* Style the copy icon */
main .share-container .share-content .copy-icon i {
    font-size: 18px;
    color: #007bff;
}

/* Adjust widths as needed */
main .share-container .share-content {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
}

main .share-link {
    background: var(--main-panel-bg-color);
    border-radius: 5px;
    color: var(--main-panel-txt-color);
    display: flex;
    font-size: 13px;
    justify-content: center;
    padding: 5px 10px;
    width: 98%;
}

main .share-button.small {
    width: 10%;
}

main .share-button.small .fas {
    margin-right: 0px;
}

/* CSS for the floating button container */
.floating-button-container {
    position: fixed;
    bottom: 20px;
    /* Adjust the distance from the bottom as needed */
    right: 20px;
    /* Adjust the distance from the right as needed */
    z-index: 1000;
    /* Ensure it's above other elements */
}

/* CSS for the floating button itself */
.floating-button {
    background-color: #007bff;
    /* Button background color */
    color: #fff;
    /* Button text color */
    border: none;
    padding: 10px;
    border-radius: 50%;
    /* Make it rounded */
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 24px;
    /* Adjust font size */
    outline: none;
}

/* Hover effect for the button (optional) */
.floating-button:hover {
    background-color: #0056b3;
    /* Change color on hover */
}

@media (max-width: 768px) {
    main .share-container {
        flex-direction: column;
        align-items: center;
        /* Center-align content */
        margin-top: 2.5px;
    }

    main .share-container .share {
        justify-content: space-between;
        /* Space evenly */
        padding: 2.5px;
        width: 100%;
    }

    main .share-button,
    main .share-link {
        width: 100%;
    }
}
/* Wrapper styling */
#filter-position-community-wrapper {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    position: relative;
}

/* Community filter styling */
#filter-position-community-wrapper #filter-community {
    background: #39495e;
    padding: 7px 7px 6px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Dropdown menu styling */
#filter-position-community-wrapper ul {
    display: none;
    /* Hidden by default */
    position: absolute;
    top: 100%;
    left: 0;
    list-style: none;
    background: #39495e;
    padding: 7px;
    margin: 0;
    border-radius: 3px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    z-index: 10;
    width: -webkit-max-content;
    width: max-content;
}

#filter-community-wrapper:hover ul {
    display: none;
}

/* Keep dropdown visible when hovering over the parent or dropdown */
#filter-community:hover ul,
#filter-community ul:hover {
    display: block;
}

/* List item styling */
#filter-community ul li {
    margin: 5px 0;
    cursor: pointer;
    border-radius: 3px;
    background-color: #4a596f;
    text-align: center;
}

/* Hover effect for list items */
#filter-community ul li:hover {
    background-color: #52647d;
}

/* Styling for flag images */
#filter-position-community-wrapper img {
    height: 16px;
    display: inline-block;
}

#filter-position-community-wrapper #server-select {
    background-color: #00060d;
    color: var(--main-panel-txt-color);
    border: 1px solid #081938;
    width: 250px;
    padding: 8px;
}

#filter-position-community-wrapper #position-select {
    background-color: #00060d;
    color: var(--main-panel-txt-color);
    border: 1px solid #081938;
    width: 80px;
    padding: 8px;
}

#filter-position-community-wrapper #galaxy-select {
    background-color: #00060d;
    color: var(--main-panel-txt-color);
    border: 1px solid #081938;
    width: 80px;
    padding: 8px;
}

#filter-position-community-wrapper {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    flex-wrap: wrap; 
}

#filter-community-wrapper {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px; 
    flex: 1 1; 
}

#filter-position-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 10px;
    gap: 10px;
    flex: 1 1; 
}

/* Media query for small screens */
@media (max-width: 768px) {
    #filter-position-community-wrapper {
        flex-direction: column;
        align-items: flex-start; 
    }

    #filter-community-wrapper, #filter-position-wrapper {
        flex: none;
        width: 100%;
    }

    #filter-position-wrapper {
        justify-content: flex-start;
        padding: 7px 7px 6px;
    }

    #filter-server-wrapper {
        max-width: none;
        width: 94%
    }
}

.filters-panel {
    background-color: #1e2b39;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-top: 20px;
    transition: max-height 0.3s ease;
    /* max-height: 20px; */
}

#options-filters {
    background-color: #06090e;
    border-radius: 5px;
    margin-top: 5px;
}
#lanx-calc-panel {
    margin: 20px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

#lanx-calc-form {
    background: #000e16;
    width: 100%;
    border-radius: 7px;
    box-shadow: 0 0 10px rgba(0,0,0,.6);
    display: block;
    margin-top: 15px;
    max-width: 60%;
}

#lanx-calc-form .container {
    padding: 10px;
}

#lanx-calc-form .container .bordered-panel{
    border: 1px solid #a6c9e2;
    border-radius: 5px;
}

#lanx-calc-form .container .input-table {
    width: 100%;
    margin: 0 auto;
}

#lanx-calc-form .container .input-field,
#lanx-calc-form .container .select-field {
    width: 100%;
    border-radius: 3px;
    padding: 1.2%;
    background-color: #00060d;
    color: var(--main-panel-txt-color);
    border: 1px solid #081938;
    text-align: center;
}

#lanx-calc-form .container .input-field.invalid,
#lanx-calc-form .container .select-field.invalid {
    border-color: red;
}

#lanx-calc-form .container .select-field {
    width: 100%!important;
}

#lanx-calc-form .container .checkbox-field {
    width: 15px;
}

#lanx-calc-form .container .section-title {
    cursor: default;
}

#lanx-calc-form .container .label-cell {
    width: 150px; /* Adjust the width as needed */
    text-align: left;
    padding-right: 10px; /* Add some spacing between label and input */
}

#lanx-calc-form .container .lanx-calc-btn {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    margin-top: 20px;
}

#lanx-calc-form .container .lanx-calc-btn .btn {
    margin-top: 0;
    color: #fff;
    cursor: pointer;
    color: #f1f1f1;
    padding: 10px 20px;
    margin-left: 3%;
    font-weight: 300;
    line-height: 1.3;
    font-size: 15px;
    margin-bottom: 5px;
    background-image: -webkit-linear-gradient(top,#050f21,#050e1e);
    border: 1px solid #081938;
    background-size: contain;
    transition-duration: .5s
}

#lanx-calc-form .container .lanx-calc-btn .btn .fas {
    margin-right: 5px;
}

@media (max-width: 768px) {
    #lanx-calc-form {
        max-width: 100%;
    }

    #lanx-calc-form .container .input-table {
        width: 100%;
        margin: 0 auto;
    }

    #lanx-calc-form .container .checkbox-field {
        width: auto;
    }

    #lanx-calc-form .container .label-cell {
        padding-right: 0; /* No right padding for labels */
    }
}
#not-found-page {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    cursor: default;
    -webkit-user-select: none;
    user-select: none;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    flex-direction: column;
    height: 100%;
}

#not-found-page aside,
#not-found-page section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#not-found-page aside {
    background: #000;
    flex-shrink: 1;
    padding: 30px 20px;
}

#not-found-page aside p {
    margin: 0;
    color: #999999;
    font-size: 14px;
    line-height: 24px;
}

#not-found-page aside a {
    color: #fff;
    text-decoration: none;
}

#not-found-page section span {
    font-size: 24px;
    font-weight: 500;
    display: block;
    border-bottom: 1px solid #eaeaea;
    text-align: center;
    padding-bottom: 20px;
    width: 100px;
}

#not-found-page section p {
    font-size: 14px;
    font-weight: 400;
}

#not-found-page section span+p {
    margin: 20px 0 0 0;
    text-align: center;
}

@media (min-width: 768px) {
    #not-found-page section {
        height: 40px;
        flex-direction: row;
    }

    #not-found-page section span,
    #not-found-page section p {
        height: 100%;
        line-height: 40px;
    }

    #not-found-page section span {
        border-bottom: 0;
        border-right: 1px solid #eaeaea;
        padding: 0 20px 0 0;
        width: auto;
    }

    #not-found-page section span+p {
        margin: 0;
        padding-left: 20px;
    }

    #not-found-page aside {
        padding: 50px 0;
    }

    #not-found-page aside p {
        max-width: 520px;
        text-align: center;
    }
}
footer {
	color: #fff;
	text-align: center;
	padding: 10px 0;
	max-width: 1100px;
	margin: 0 auto;
}

footer a {
	color: #fff;
	text-decoration: none;
	border-bottom: 1px solid #fff;
	transition: border-bottom-color 0.3s;
}

footer a:hover {
	border-bottom-color: transparent;
}

@media only screen and (max-width: 1100px) {
	footer {
		width: 94%;
	}
}

@media only screen and (max-width: 600px) {
    /* Adjust the screen width breakpoint as needed */
    footer a {
        display: inline-block; /* Display links inline for larger screens */
        margin-bottom: 5px; /* Remove margin for horizontal alignment */
    }
}


::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-corner {
    display: none;
}

::-webkit-scrollbar-thumb {
    background: rgba(115,205,240,.9);
}

::-webkit-scrollbar-track {
    background: rgba(115,205,240,.2);
}

::-webkit-scrollbar-track-piece {
}

::selection {
    background: #09c;
    color: #fff;
    text-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #081938 !important;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #00060d inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

main {
	/*height: 100vh;*/
	/* display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center; */
	max-width: 1100px;
	margin: 0 auto;
	margin-top: 50px;
	position: relative;
	z-index: 1;
}

* {
    box-sizing: border-box;
}

main.discord-active {
    margin: 0;
    width: 100%;
    max-width: 100%;
}

.mt5 {
	margin-top: 5px;
}

.clearfix {
    display: block;
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.notification {
    padding: 8px;
    color: white;
    opacity: 0.83;
    transition: opacity 0.6s;
    /* margin-bottom: 15px; */
    margin: 20px 15px;
}

.notification.warning {
    background-color: #ff9800;
}

@media only screen and (max-width: 1100px) {
	main {
		width: 94%;
	}
}

@keyframes snow {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(100vh);
        opacity: 0;
    }
}

.snow-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Ensure the snow doesn't interfere with interactions */
    overflow: hidden; /* Ensure no scrollbars appear */
    z-index: 9999; /* Keep it above all other elements */
}

.snowflake {
    position: absolute;
    top: -10px;
    color: white;
    font-size: 16px;
    pointer-events: none;
    animation: snow 10s linear infinite;
}

.snowflake:nth-child(odd) {
    animation-duration: 8s;
}
html {
    overflow: auto;
    height: 100%;
}

body {
    margin: 0;
    font-weight: 300;
    font-family: Yantramanav, Helvetica, sans-serif;
    background-color: #1E2B39;
    font-size: 15px;
    line-height: 1;
    color: #fff;
    min-block-size: 100vh;
    background: #19191f url(/assets/img/bg.svg) 50% 28px no-repeat;
    background-size: 1760px 852px;
    background-attachment: fixed;
}

body.christmass {
    background: #19191f url(/assets/img/bg-xmass.webp) 50% 0 no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

#popup {
    display: none;
    position: relative;
    z-index: 100;
}

#popup #popup-shadow {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: rgba(0,0,0,0.6);
}

#popup #popup-container {
    padding: 1%;
    background: #1E2B39;
    position: absolute;
    top: 25px;
    z-index: 1;
    max-width: 800px;
    width: 96%;
    left: 50%;
    box-shadow: 0 2px 50px rgba(255,255,255,0.1);
    border-radius: 5px;
    margin-bottom: 50px;
}

#popup #popup-container #popup-close {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.5em;
}

#popup #popup-container .topraider {
    line-height: 1.2;
}

.h5,h5{margin-bottom:.5rem;font-weight:500;line-height:1.2}

.h5,h5{font-size:1.25rem}

.text-center{text-align:center!important}

.img-fluid{max-width:100%;height:auto}

.display-3{font-size:4.5rem;font-weight:300;line-height:1.2}

.font-16 {
    font-size:16;
}

.font-8 {
    font-size:8;
}

.font-10 {
    font-size:10;
}

.color-red {
    color:#f23f42;
}

.color-green {
    color:#008000;
}

.color-blue {
    color:#1c84be
}

.color-orange {
    color:#fc850c
}

.color-purple {
    color:#9A9AC1
}

.color-light-blue {
    color:#8DCEEC
}

.color-light-green {
    color:#41AA9C
}

.mt-5 {
    margin-top: 5px;
}
