.acs-protected-access-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.acs-protected-access-container .card {
    background: #000e16;
    border-radius: 7px;
    box-shadow: 0 0 10px rgba(0,0,0,.6);
    display: block;
    margin-top: 15px;
    min-width: 360px;
}

.acs-protected-access-container .card .mb-0 {
    margin-bottom: 0!important;
}

.acs-protected-access-container .card .card-body {
    flex: 1 1 auto;
    padding: 1.25rem 1.25rem;
}

.acs-protected-access-container .card .card-body .text-center {
    text-align: center!important;
}

.acs-protected-access-container .card .card-body .mb-3 {
    margin-bottom: 1.25rem!important;
}

.acs-protected-access-container .card .card-body .alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.acs-protected-access-container .card .card-body .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    margin-top: 8px;
}

.acs-protected-access-container .card .card-body .text-muted {
    --text-opacity: 1;
    color: rgba(255,255,255,.75)!important;
}

.acs-protected-access-container .card .card-body .d-block {
    display: block!important;
}

.acs-protected-access-container .card .card-body .form-label {
    margin-bottom: 0.5rem;
}

.acs-protected-access-container .card .card-body label {
    display: inline-block;
}

.acs-protected-access-container .card .card-body .form-control-feedback {
    position: relative;
}

.acs-protected-access-container .card .card-body .btn {
    margin-top: 0;
    color: #fff;
    cursor: pointer;
    color: #f1f1f1;
    padding: 10px 20px;
    font-weight: 300;
    font-size: 15px;
    margin-bottom: 5px;
    background-image: -webkit-linear-gradient(top,#050f21,#050e1e);
    border: 1px solid #081938;
    background-size: contain;
    transition-duration: .5s;
}

.acs-protected-access-container .card .card-body .btn .fas {
    margin-right: 5px;
}

.acs-protected-access-container .card .card-body .w-100 {
    width: 100%!important;
}

.acs-protected-access-container .card .card-body .form-control {
    display: block;
    width: 100%;
    padding: 0.5rem 0.875rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5715;
    color: #fff;
    background-color: #2c2d33;
    background-clip: padding-box;
    border: 1px solid #4d4d51;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    box-shadow: 0 0 0 0 transparent;
    transition: border-color var(--transition-base-timer) ease-in-out,box-shadow var(--transition-base-timer) ease-in-out;
}

.acs-protected-access-container .card .card-body .text-input.invalid{
    border-color: var(--main-invalid-bg-color);
    color: var(--main-invalid-txt-color);
}


@media (max-width: 768px) {
    .acs-protected-access-container .card {
        min-width: 0;
    }
}
