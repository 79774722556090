.select-player-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.select-player-side .label {
  color: var(--main-panel-txt-color);
  font-size: 17px;
}

.select-player-side .button {
  margin-top: 5px;
  width: 200px;
  font-size: 15px;
  margin-bottom: 5px;
  background-image: -webkit-linear-gradient(top,#050f21,#050e1e);
  border: 1px solid #081938;
  background-size: contain;
}