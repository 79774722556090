:root {
    --main-panel-bg-color: #0a1729;
    --main-panel-txt-color: rgb(255, 255, 255);
    --main-paneltitle-bg-color: #14283a;
    --main-paneltitle-txt-color: rgb(255, 255, 255);
    --main-input-bg-color: rgb(255, 255, 255, 0.9);
    --main-input-txt-color: rgb(0, 0, 0);
    --main-invalid-bg-color: rgb(255, 8, 0);
    --main-invalid-key-bg-color: rgb(255, 8, 0, 0.3);
    --main-invalid-message-color: rgb(255, 8, 0);
    --main-gain-bg-color: rgb(13, 255, 0);
    --main-loss-bg-color: rgb(252, 3, 32);
}

main .loading-main {
    display: flex;
    height: 100%;
    margin-top: 200px;
    justify-content: center;
}

main .loading-main .fas {
    animation: loading-spinner 2s linear infinite;
    color: var(--main-panel-txt-color);
    ;
    font-size: 100px;
}

main .api-key-list-item {
    width: 100%;
    background-color: var(--main-panel-bg-color);
    color: var(--main-panel-txt-color);
    margin-bottom: 5px;
    font-size: 14px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    overflow-wrap: anywhere;
}

main .api-key-list-item .fas {
    margin-right: 10px;
    cursor: pointer;
}

main .share-container {
    width: 100%;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 2.5px;
    display: flex;
}

main .share-container .share {
    display: flex;
    justify-content: flex-end;
    padding: 2.5px;
    width: 98%;
}

main .share-container .share .title {
    color: var(--main-paneltitle-txt-color);
    font-size: 15px;
    margin-left: 10px;
}

main .share-button {
    width: 100%;
    background-color: var(--main-paneltitle-bg-color);
    color: var(--main-paneltitle-txt-color);
    border-radius: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 80%;
}

main .share-container .share-content .copy-icon {
    margin-left: 10px;
    cursor: pointer;
}

/* Style the pin code */
main .share-container .share-content .pincode {
    font-size: 13px;
    color: #777;
    margin-left: 10px;
}

/* Style the copy icon */
main .share-container .share-content .copy-icon i {
    font-size: 18px;
    color: #007bff;
}

/* Adjust widths as needed */
main .share-container .share-content {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
}

main .share-link {
    background: var(--main-panel-bg-color);
    border-radius: 5px;
    color: var(--main-panel-txt-color);
    display: flex;
    font-size: 13px;
    justify-content: center;
    padding: 5px 10px;
    width: 98%;
}

main .share-button.small {
    width: 10%;
}

main .share-button.small .fas {
    margin-right: 0px;
}

/* CSS for the floating button container */
.floating-button-container {
    position: fixed;
    bottom: 20px;
    /* Adjust the distance from the bottom as needed */
    right: 20px;
    /* Adjust the distance from the right as needed */
    z-index: 1000;
    /* Ensure it's above other elements */
}

/* CSS for the floating button itself */
.floating-button {
    background-color: #007bff;
    /* Button background color */
    color: #fff;
    /* Button text color */
    border: none;
    padding: 10px;
    border-radius: 50%;
    /* Make it rounded */
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 24px;
    /* Adjust font size */
    outline: none;
}

/* Hover effect for the button (optional) */
.floating-button:hover {
    background-color: #0056b3;
    /* Change color on hover */
}

@media (max-width: 768px) {
    main .share-container {
        flex-direction: column;
        align-items: center;
        /* Center-align content */
        margin-top: 2.5px;
    }

    main .share-container .share {
        justify-content: space-between;
        /* Space evenly */
        padding: 2.5px;
        width: 100%;
    }

    main .share-button,
    main .share-link {
        width: 100%;
    }
}