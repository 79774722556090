#page-report * {
    box-sizing: unset;
}

#report-content-wrapper {
    padding: 1%;
    background: #1E2B39;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-top: 20px;
}

#report-content {
    padding: 10px;
    background-color: #06090E;
    border-radius: 5px;
}

#report-header .menu {
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    width: 200px;
}

#report-header ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#report-header ul li {
    text-align: left;
    margin-bottom: 15px;
}

#report-header ul li a {
    display: block;
    color: #f1f1f1;
    background-image: -webkit-linear-gradient(top, #050f21, #050e1e);
    box-shadow: 0 0 10px #0009;
    border-radius: 7px;
    text-decoration: none;
    font-size: 1.3em;
    padding: 3%;
    border: 1px solid #081938;
    background-size: contain;
}

#report-content pre {
    margin: 0;
    font-family: inherit;
    white-space: pre-line;
}

#report-content pre img {
    height: auto !important;
    max-width: 100%;
}

#report-content .topraider {
    line-height: 1.2;
}

#report-content a {
    color: #ccc;
}

#report-players .report-players-block .report-players-summary {
    margin-top: 10px;
    font-size: 1.7em;
}

#report-players .report-players-block .report-summary-positive {
    color: rgb(63, 255, 63);
}

#report-players .report-players-block .report-summary-negative {
    color: rgb(255, 51, 51);
}

#report-players .report-players-block {
    width: 36%;
    padding: 2%;
    background: #1E2B39;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

#report-players #report-attackers {
    float: left;
}

#report-players #report-defenders {
    float: right;
}

#report-players #report-versus {
    width: 10%;
    margin: 0 auto;
    text-align: center;
    font-size: 3em;
    font-weight: normal;
}

#report-players .report-players-block h4 {
    margin: 0;
    font-size: 1.7em;
}

#report-players .report-players-block ul {
    margin: 10px 0 0;
    padding: 0;
    list-style: none;
    background-color: #06090E;
    padding: 5%;
    border-radius: 5px;
}

#report-players .report-players-block ul li {
    font-size: 1.2em;
    margin-bottom: 5px;
}

#report-players .report-players-block ul li:last-child {
    margin-bottom: 0;
}

.subtitle {
    font-size: 4em;
    font-weight: 100;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 1);
}

#report-players h3 span {
    font-weight: 100;
    float: right;
}

.react-loader {
    color: #fff;
    height: 64px;
    width: 64px;
    margin: auto;
}

.options-panel {
    background-color: #1e2b39;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-top: 20px;
    transition: max-height 0.3s ease;
    /* max-height: 20px; */
    overflow: hidden;
}

.options-panel.expanded {
    max-height: 200px;
    /* Adjust the max height as needed */
}

.options-panel-header {
    font-weight: bold;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#cr-options {
    background-color: #06090e;
    border-radius: 5px;
    padding: 10px;
    margin-top: 5px;
}

#cr-options select {
    background-color: #00060d;
    border: 1px solid #081938;
    border-radius: 3px;
    color: var(--main-panel-txt-color);
    padding: 5px;
}

.options-panel-icon {
    font-size: 1.2em;
}

@media (max-width: 50em) {
    #report-players .report-players-block {
        width: 44%;
    }

    #report-players #report-versus {
        display: none;
    }
}

@media (max-width: 37.5em) {
    #report-players h3 {
        text-align: center;
    }

    #report-players .report-players-block {
        width: 96%;
        padding: 2%;
        float: none;
        margin-bottom: 15px;
    }

    #report-players .report-players-block ul {
        padding: 2%;
    }

    .options-panel {
        margin-top: 0px;
    }

    #report-header .menu {
        display: none;
    }
}